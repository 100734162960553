const racingOddsBoostTable = {
  1.01: 1.01,
  1.02: 1.02,
  1.03: 1.03,
  1.04: 1.04,
  1.05: 1.05,
  1.06: 1.06,
  1.07: 1.07,
  1.08: 1.08,
  1.09: 1.09,
  1.1: 1.11,
  1.11: 1.12,
  1.12: 1.13,
  1.13: 1.14,
  1.14: 1.15,
  1.15: 1.16,
  1.16: 1.18,
  1.17: 1.19,
  1.18: 1.2,
  1.19: 1.21,
  1.2: 1.22,
  1.21: 1.23,
  1.22: 1.24,
  1.23: 1.25,
  1.24: 1.26,
  1.25: 1.27,
  1.26: 1.28,
  1.27: 1.29,
  1.28: 1.3,
  1.29: 1.31,
  1.3: 1.32,
  1.31: 1.33,
  1.32: 1.34,
  1.33: 1.35,
  1.34: 1.36,
  1.35: 1.37,
  1.36: 1.39,
  1.37: 1.4,
  1.38: 1.41,
  1.39: 1.42,
  1.4: 1.43,
  1.41: 1.44,
  1.42: 1.45,
  1.43: 1.46,
  1.44: 1.47,
  1.45: 1.48,
  1.46: 1.49,
  1.47: 1.5,
  1.48: 1.51,
  1.49: 1.52,
  1.5: 1.53,
  1.51: 1.54,
  1.52: 1.55,
  1.53: 1.56,
  1.54: 1.57,
  1.55: 1.58,
  1.56: 1.59,
  1.57: 1.6,
  1.58: 1.61,
  1.59: 1.62,
  1.6: 1.63,
  1.61: 1.64,
  1.62: 1.65,
  1.63: 1.66,
  1.64: 1.67,
  1.65: 1.68,
  1.66: 1.69,
  1.67: 1.7,
  1.68: 1.71,
  1.69: 1.72,
  1.7: 1.75,
  1.75: 1.85,
  1.8: 1.9,
  1.85: 1.95,
  1.9: 2,
  1.95: 2.05,
  2: 2.15,
  2.05: 2.2,
  2.1: 2.25,
  2.15: 2.3,
  2.2: 2.35,
  2.25: 2.4,
  2.3: 2.45,
  2.35: 2.5,
  2.4: 2.55,
  2.45: 2.6,
  2.5: 2.7,
  2.6: 2.8,
  2.7: 2.9,
  2.8: 3,
  2.9: 3.1,
  3: 3.3,
  3.1: 3.4,
  3.2: 3.5,
  3.3: 3.6,
  3.4: 3.7,
  3.5: 3.8,
  3.6: 3.9,
  3.7: 4,
  3.8: 4.1,
  3.9: 4.2,
  4: 4.4,
  4.2: 4.6,
  4.4: 4.8,
  4.6: 5,
  4.8: 5.5,
  5: 6,
  5.5: 6.5,
  6: 7,
  6.5: 7.5,
  7: 8,
  7.5: 8.8,
  8: 9,
  8.5: 9.5,
  9: 10,
  9.5: 11,
  10: 12,
  11: 13,
  12: 14,
  13: 15,
  14: 16,
  15: 17,
  16: 18,
  17: 19,
  18: 20,
  19: 21,
  20: 23,
  21: 23,
  23: 26,
  26: 31,
  31: 34,
  34: 41,
  41: 51,
  46: 56,
  51: 61,
  56: 67,
  61: 71,
  67: 81,
  71: 81,
  81: 91,
  91: 100,
  100: 126,
  126: 145,
  150: 180,
  180: 215,
  215: 250,
  250: 285,
  285: 325,
  325: 355,
  355: 400,
  400: 450,
  450: 500,
  500: 560,
  560: 600,
  600: 650,
  650: 700,
  700: 750,
  750: 800,
  800: 850,
  850: 900,
  900: 1000,
  1000: 1001,
  first: () => 1.01,
  last: () => 1000,
};

const CalculateOddsBoost = (odds = 1.0) => {
  if (odds < racingOddsBoostTable.first() || odds > racingOddsBoostTable.last()) {
    return odds;
  }
  if (racingOddsBoostTable[odds]) {
    return racingOddsBoostTable[odds];
  }
  let lowerEntry = 0;
  Object.values(racingOddsBoostTable).forEach((k) => {
    if (k > lowerEntry && k < odds) {
      lowerEntry = k;
    }
  });
  return racingOddsBoostTable[lowerEntry] + (odds - lowerEntry);
};

export default CalculateOddsBoost;
