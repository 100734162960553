import React, { useCallback, useMemo } from 'react';
import { Bus, getAvailablePages, SiteConfigManager, useApplicationState } from '@apollo/core';
import { NavLink } from 'react-router-dom';
import Theme from 'themeSource/custom';
import { SIDEBAR_ITEMS } from '@apollo/core/src/constants';
import { availableClientProfileRoutes, COMPONENT_TYPES } from '@apollo/routing';
import RacingSidebar from '../../../features/Racing/RacingSidebar/RacingSidebar';
import SportsSidebar from '../../../features/Sports/SportsSidebar/SportsSidebar';
import Label from '../I18n/Label';
import { SidebarActions, useSidebarState } from '../../../state/Sidebar/Sidebar';
import LiveChatLink from '../LiveChatLink/LiveChatLink';
import SidebarHomeLinks from './SidebarHomeLinks';
import SidebarSocial from './SidebarSocial/SidebarSocial';
import { RacingIcon } from '../Racing/RacingIcon';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

const SidebarHead = () => <div className='sidebar-head' />;
const SidebarNextToJump = () => null;

const Sidebar = () => {
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);
  const [sidebarState, sidebarDispatcher] = useSidebarState();
  const { layout } = useApplicationState();

  const ThemeSidebarHeader = Theme.SidebarHeader || SidebarHead;
  const ThemeSidebarNextToJump = Theme.SidebarNextToJump || SidebarNextToJump;

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);

  const racingPath = useMemo(
    () => SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.RACING}`),
    [],
  );

  const onClickLinkHandler = useCallback(() => {
    if (layout.tabletDevice) {
      Bus.send({
        event: Bus.events.layout.setSideBarLeft,
        data: false,
      });
    }
  }, [layout.tabletDevice]);

  const tabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs).reduce((acc, item) => {
      acc[item.type] = item;

      return acc;
    }, {}),
    [clientProfileTabs],
  );

  const setSidebar = useCallback(
    (key) => {
      sidebarDispatcher({
        type: SidebarActions.SET_EXPANDED_ITEMS,
        payload: {
          key,
        },
      });
    },
    [sidebarDispatcher],
  );

  const isSeparateRaceTypeAll = Theme?.SEPARATE_RACE_TYPE_ALL;
  const isSeparateSidebarNextToJump = Theme?.SEPARATE_SIDEBAR_NEXT_TO_JUMP;

  return (
    <>
      {isSeparateSidebarNextToJump && <div className='sidebar_container sidebar_container--next-to-jump'><ThemeSidebarNextToJump /></div>}

      <div className='sidebar_container'>
        <div className='sidebar-accordion'>
          <ThemeSidebarHeader />

          <div className='sidebar-body sidebar-body--race'>
            <div className='sidebar-tree main__page__left__tree'>
              <div className='sidebar-tree__section'>
                <SidebarHomeLinks />
              </div>

              {!isSeparateSidebarNextToJump && <ThemeSidebarNextToJump />}

              {isSeparateRaceTypeAll && (
              <div className='sidebar-tree__section-title sidebar-tree__section-title--race-all'>
                <NavLink
                  to={`${racingPath}/TODAY`}
                  exact
                  className='sidebar-tree__section-title--link'
                >
                  <RacingIcon id='HORSE' name='Horses' />
                  <Label message='todays_races' className='name' />
                </NavLink>
              </div>
              )}
              <div className='sidebar_race'>
                <RacingSidebar
                  isExpanded={sidebarState?.sidebarExpandedItems?.includes(SIDEBAR_ITEMS.RACING)}
                  handleExpand={setSidebar}
                />
              </div>
              <div className='sidebar_sport'>
                <SportsSidebar
                  isExpanded={sidebarState?.sidebarExpandedItems?.includes(SIDEBAR_ITEMS.SPORTS)}
                  handleExpand={setSidebar}
                />
              </div>

              <div className='sidebar_quick'>
                <div className='sidebar-tree__section-title'>
                  <Label message='QuickLinks' className='name' />
                </div>
                <div className='sidebar-tree__section-data expanded'>
                  <NavLink
                    className='sidebar-tree__section-item payments'
                    to={`${clientProfilePath}/${tabs?.payment?.link}`}
                    activeClassName='active'
                    onClick={onClickLinkHandler}
                  >
                    <Label message={tabs?.payment?.name} />
                  </NavLink>
                  <NavLink
                    className='sidebar-tree__section-item history'
                    to={`${clientProfilePath}/history/transaction-history`}
                    activeClassName='active'
                    onClick={onClickLinkHandler}
                  >
                    <Label message={tabs?.history?.name} />
                  </NavLink>
                  <NavLink
                    className='sidebar-tree__section-item settings'
                    to={`${clientProfilePath}/${tabs?.settings?.link}`}
                    activeClassName='active'
                    onClick={onClickLinkHandler}
                  >
                    <Label message={tabs?.settings?.name} />
                  </NavLink>
                </div>
              </div>

              <SidebarSocial />

              <div className='sidebar_help'>
                <div className='sidebar-tree__section-title'>
                  <Label message='Help' className='name' />
                </div>
                <div className='sidebar-tree__section-data expanded'>
                  <LiveChatLink />
                  <NavLink
                    className='sidebar-tree__section-item responsible-gambling'
                    to={`${clientProfilePath}/${tabs?.responsibleGaming?.link}`}
                    activeClassName='active'
                    onClick={onClickLinkHandler}
                  >
                    <Label message={tabs?.responsibleGaming?.name} />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='sidebar-foot' />
      </div>
    </>
  );
};

export default Sidebar;
