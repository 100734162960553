import React from 'react';
import { constants } from '@apollo/core';
import { outcomeStatuses, SPECIAL_MARKETS } from '../../../core/constants';
import Label from '../../../shared/components/I18n/Label';
import Odds from '../../../shared/components/Odds';
import useTranslate from '../../../shared/components/I18n/Interpreter';

const { BET_TYPE, BONUS_TYPE } = constants;

const BetCouponOdds = ({ betType, updatedOdds, bonusInfoList, outcome, oddsMinDecimal = 1 }) => {
  const {
    odds,
    outcomeInfo: { outcomeStatus, marketTypeId },
  } = outcome;

  const t = useTranslate();

  const hasOddsBoostBonus = betType === BET_TYPE.SINGLE
    && bonusInfoList?.some((bonus) => [BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.PROMO_ODDS].includes(bonus.bonusType));

  const bonusType = hasOddsBoostBonus ? bonusInfoList[0].bonusType : '';

  const percentage = hasOddsBoostBonus && updatedOdds?.odds && odds
    ? Math.round(((updatedOdds.odds - odds) / odds) * 100)
    : 0;

  if (
    outcomeStatus === outcomeStatuses.NOT_DEFINED
    && SPECIAL_MARKETS[marketTypeId]
    && !hasOddsBoostBonus
  ) {
    return <Label message='tbd' />;
  }

  if (updatedOdds) {
    return hasOddsBoostBonus && percentage >= 0 ? (
      <div className='odd boosted'>
        <div className='odd__default'>
          <i className={`icons icon--${bonusType?.toLowerCase()}`} title={t(bonusType)} />
          <span>
            {outcomeStatus === outcomeStatuses.NOT_DEFINED && SPECIAL_MARKETS[marketTypeId] ? (
              `+${percentage}%`
            ) : (
              <Odds value={updatedOdds.odds} decimal={3} minDecimal={oddsMinDecimal} />
            )}
          </span>
        </div>
        <div className='odd__changed'>
          {outcomeStatus === outcomeStatuses.NOT_DEFINED && SPECIAL_MARKETS[marketTypeId] ? (
            <Label message='tbd' />
          ) : (
            <span>
              <Odds value={odds} decimal={3} minDecimal={oddsMinDecimal} />
            </span>
          )}
        </div>
      </div>
    ) : (
      <>
        <div className='odd__default'>
          <span>
            <Odds value={odds} decimal={3} minDecimal={oddsMinDecimal} />
          </span>
        </div>
        <Odds value={updatedOdds.odds} decimal={3} minDecimal={oddsMinDecimal} />
      </>
    );
  }

  return <Odds value={odds} decimal={3} minDecimal={oddsMinDecimal} />;
};

export default BetCouponOdds;
