import React from 'react';
import { SiteConfigManager, useSettings } from '@apollo/core';
import { isEmpty, map } from 'lodash';
import Label from '../../I18n/Label';

const SidebarSocial = () => {
  const socialLinks = SiteConfigManager.getConfig('config.config.social_links', []);
  if (isEmpty(socialLinks)) {
    return null;
  }

  return (
    <div className='sidebar_social'>
      <div className='sidebar-tree__section-title'>
        <Label message='Follow us' className='name' />
      </div>
      <div className='sidebar-tree__section-data expanded'>
        {map(socialLinks, (socialLink) => {
          const { key, text, link } = socialLink;
          return (
            <a
              key={key}
              className='sidebar-tree__section-item'
              href={link}
              target='_blank'
              rel='noreferrer'
            >
              <Label message={text} />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SidebarSocial;
