import {
  BET_STATUSES,
  RaceLogicMarkets,
  RaceMarketsMultilegsTabs,
  RaceMarketsTabs,
} from '@apollo/core/src/constants';
import { constants } from '@apollo/core';

export const defaultHeaderKeys = {
  ProjectID: 'X-Project-ID',
  LocalPlatform: 'X-Local-Platform',
  LocalTimeZone: 'X-Local-Time-Zone',
  LocalLanguage: 'X-Local-Language',
};

export const sportProviders = {
  LSport: 'lsport',
  Pinnacle: 'pinnacle',
  Sportcast: 'sportcast',
  Apollo: 'apollo',
};

export const THEME_NAMES = {
  betMax: 'betmax.com',
  betKings: 'betkings.com.au',
  puntPac: 'puntpac.com',
  winners: 'winners.com.au',
  MGMBet: 'mgmbet.com.au',
  betPlay: 'betplay.com.au',
  betShop: 'betshop.com.au',
  favBet: 'favbet.com.au',
  '123Bet': '123bet.com.au',
  BetPinnacle: 'betpinnacle.com.au',
  bearBet: 'bearbet.com.au',
};

// export const providerToSgmProvider = {
//   [sportProviders.Pinnacle]: `${sportProviders.Pinnacle}_same_game_multi`,
// };
//
// providerToSgmProvider.getProviderBySgmName = (sgmName) => Object
//   .entries(providerToSgmProvider)
//   .find(([key, value]) => value === sgmName)[0];
//
// providerToSgmProvider.isSgmProvider = (name) => Object
//   .values(providerToSgmProvider)
//   .includes(name);
//
// export const defaultSportProvider = sportProviders.Pinnacle;

export const sportBetTypes = {
  Single: 'single',
  SameGameMulti: 'same_game_multi',
};

export const cmsStaticBlock = {
  Footer: 'footer',
  FooterMobile: 'footer-mobile',
  FooterLicense: 'footer-license',
  DepositPrefix: 'deposit',
  WithdrawalPrefix: 'withdrawal',
  VerifyDocuments: 'verify-documents',
  SetSpendingLimits: 'set-spending-limits',
  MakeADeposit: 'make-a-deposit',
  DepositFooter: 'deposit-footer',
  DepositLimit: 'deposit-limit',
  MakeAWithdrawal: 'make-a-withdrawal',
  ConfirmADeposit: 'confirm-a-deposit',
  ConfirmAWithdrawal: 'confirm-a-withdrawal',
  SelfCloseAccount: 'self-close-account',
  ClientCommunication: 'client-communication',
  ClientBlocked: 'client-blocked',
  ExceptionScreen: 'exception-screen',
  VersionBlock: 'version-block',
  SignUpHeader: 'signup-header',
  SignUpBanner: 'signup-banner',
  SignUpBannerRespGambling: 'signup-banner-resp-gambling',
  ConfirmCashOut: 'confirm-cash-out',
  SelfExclusionMessage: 'self-exclusion-message',
  SelfExclusionConfirmation: 'self-exclusion-confirmation-pop',
  BetSlipPromoBlock: 'betslip-promo-block',
  AffiliateHeader: 'affiliate-page-header',
};

export const cmsNavBlock = {
  Footer: 'footer',
};

export const cmsWidgetPageBlock = {
  HomePageRacing: 'home-page-racing',
  HomePageSport: 'home-page-sport',
};

export const cmsWidgetComponent = {
  RacingFeaturedEvent: 'RACING_FEATURED_EVENT',
  SportFeaturedEvent: 'SPORT_FEATURED_EVENT',
  SportUpcoming: 'SPORT_UPCOMING',
  SportTopSports: 'SPORT_TOP_SPORTS',
  SportTopTournaments: 'SPORT_TOURNAMENTS',
};

export const appEvents = {
  cordovaDeviceReady: 'cordova:deviceReady',
  resetScroll: 'app:resetScroll',
  devicePause: 'device:pause',
  deviceResume: 'device:resume',
  deviceNetworkOffline: 'device:network:offline',
  deviceNetworkOnline: 'device:network:online',
};

export const unixTime = {
  mins5: 60000 * 5,
  mins10: 60000 * 10,
  mins15: 60000 * 15,
  mins30: 60000 * 30,
  hours1: 3600000,
  hours3: 3600000 * 3,
  hours6: 3600000 * 6,
  hours12: 3600000 * 12,
  hours24: 3600000 * 24,
  hours48: 3600000 * 48,
  hours72: 3600000 * 72,
  hours120: 3600000 * 120,
};

export const currencySymbols = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BRL: 'R$',
  BSD: '$',
  BTC: '฿',
  BTN: 'Nu.',
  BWP: 'P',
  BYR: 'Br',
  BYN: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$U',
  UZS: 'so\'m',
  VEF: 'Bs',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  YER: '﷼',
  ZAR: 'R',
  ZWD: 'Z$',
};

export const paymentTypes = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
  BONUS_ACTIVATE: 'BONUS_ACTIVATE',
  BONUS_COMPLETE: 'BONUS_COMPLETE',
  BONUS_DEACTIVATE: 'BONUS_DEACTIVATE',
  CORRECTION_BALANCE_DEPOSIT: 'CORRECTION_BALANCE_DEPOSIT',
  CORRECTION_BALANCE_WITHDRAWAL: 'CORRECTION_BALANCE_WITHDRAWAL',
  CORRECTION_BONUS_DEPOSIT: 'CORRECTION_BONUS_DEPOSIT',
  CORRECTION_BONUS_WITHDRAWAL: 'CORRECTION_BONUS_WITHDRAWAL',
};

export const transactionTypes = {
  BET_LOSE: 'BET_LOSE',
  BET_WIN: 'BET_WIN',
  BET_ROLLBACK: 'BET_ROLLBACK',
  BET_VOID: 'BET_VOID',
  BET_CANCEL: 'BET_CANCEL',
  BET_REJECT: 'BET_REJECT',
  BET_PLACE: 'BET_PLACE',
  BONUS: 'BONUS',
  BONUS_CANCEL: 'BONUS_CANCEL',
  BONUS_COMPLETE: 'BONUS_COMPLETE',
  BONUS_AFTER_BET_CANCEL: 'BONUS_AFTER_BET_CANCEL',
  BONUS_ACTIVATE: 'BONUS_ACTIVATE',
  BONUS_DEACTIVATE: 'BONUS_DEACTIVATE',
  WITHDRAWAL: 'WITHDRAWAL',
  WITHDRAWAL_CREATE: 'WITHDRAWAL_CREATE',
  WITHDRAWAL_CANCEL: 'WITHDRAWAL_CANCEL',
  WITHDRAWAL_COMPLETE: 'WITHDRAWAL_COMPLETE',
  CORRECTION_BONUS_WITHDRAWAL: 'CORRECTION_BONUS_WITHDRAWAL',
  MANUAL_CORRECTION: 'MANUAL_CORRECTION',
  CORRECTION_BALANCE_WITHDRAWAL: 'CORRECTION_BALANCE_WITHDRAWAL',
  CORRECTION_BONUS_DEPOSIT: 'CORRECTION_BONUS_DEPOSIT',
  DEPOSIT: 'DEPOSIT',
  DEPOSIT_COMPLETE: 'DEPOSIT_COMPLETE',
  DEPOSIT_CANCEL: 'DEPOSIT_CANCEL',
  CORRECTION_BALANCE_DEPOSIT: 'CORRECTION_BALANCE_DEPOSIT',
};

export const sportService = {
  PREMATCH: 'PREMATCH',
  LIVE: 'LIVE',
  ARCHIVED: 'ARCHIVED',
  UNSPECIFIED: 'UNSPECIFIED',
  RACING: 'RACING',
};

export const specialDates = {
  Today: 'TODAY',
  Tomorrow: 'TOMORROW',
};

export const outcomeStatuses = {
  NOT_DEFINED: 'NOT_DEFINED',
};

const { UNSETTLED, LOSE, WIN, VOID, CASH_OUT, CANCELLED } = BET_STATUSES;

export const betStatusesList = [UNSETTLED, LOSE, WIN, VOID, CANCELLED, CASH_OUT];

export const bonusStatuses = {
  AVAILABLE: 'AVAILABLE',
  IN_PROGRESS: 'IN_PROGRESS',
  ACTIVATED: 'ACTIVATED',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  SPEND: 'SPEND',
  EXPIRED: 'EXPIRED',
  CANCELED: 'CANCELED',
  DISABLED: 'DISABLED',
  ACTIVATION_EXPIRED: 'ACTIVATION_EXPIRED',
};

export const assignableBonuses = [
  constants.BONUS_TYPE.FREEBET,
  constants.BONUS_TYPE.ODDS_BOOST,
  constants.BONUS_TYPE.BET_RETURN,
];

export const readableBetStatuses = {
  UNSETTLED: 'Not settled',
  WIN: 'Win',
  LOSE: 'Loss',
  CASH_OUT: 'Cashed Out',
  VOID: 'Void',
  CANCELLED: 'Return',
};

export const betTypes = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
  SYSTEM: 'SYSTEM',
};

export const negativeBalanceTypes = {
  BET_PLACE: 'BET_PLACE',
  BET_LOSE: 'BET_LOSE',
  WITHDRAWAL_COMPLETE: 'WITHDRAWAL_COMPLETE',
};

export const betSettingOptions = {
  YES: 'YES',
  UP: 'UP',
  DOWN: 'DOWN',
  NO: 'NO',
  DEFAULT: 'YES',
};

export const urlSeparator = '/';
export const amountOfSymbolsToStartSearch = 3;
export const querySymbol = '?q=';
export const topTournamentsRouteSportId = 'top-tournaments';
export const marketView = 'marketView';
export const marketViews = {
  fonbet: 'fonbet',
  apollo: 'apollo',
};

export const displayLayoutTypes = {
  '2way': true,
  '2way_param': true,
  '3way': true,
  '3way_param': true,
  multiway: true,

  Handicap: true,
  handicap_3_way: true,

  tennis_game_winner: true,
  tennis_point_winner: true,
  tennis_game_deuce: true,
  tennis_game_score: true,
  tennis_race_to: true,
  tennis_correct_score: true,

  TENNIS_LEAD_AF: true,

  tt_race_to: true,
  tt_point_winner: true,

  soccer_race_to: true,
  soccer_correct_score: true,
  soccer_1x2_total: true,
  soccer_total_both_team_toscore: true,

  ice_hockey_race_to: true,
  basketball_race_to: true,
  basketball_both_to_sx: true,
  basketball_to_score: true,
  volleyball_race_to: true,
  badminton_race_to: true,
  '3way_total_param': true,
  next_goal: true,
};

export const sportIds = {
  soccer: 1,
  tennis: 2,
  basketball: 3,
  tableTennis: 7,
  volleyball: 17,
  iceHockey: 18,
  baseball: 48,
};

export const periodTypeIds = {
  tennisGamePeriodId: 58,
};

export const OPERATION_STATUS_FAILED = 'FAILED';
export const OPERATION_STATUS_SUCCEED = 'SUCCEED';

export const WITHDRAWAL_CREATE = 'WITHDRAWAL_CREATE';

// For some sports we show word 'sets' instead of 'score'
export const SPORT_IDS_WITH_SETS = [2];
// For some sports we show word 'points' instead of period description from backend
export const SPORT_IDS_WITH_POINTS = [2, 7];
// For some sports we shouldn't show period info
export const SPORT_IDS_WITHOUT_PERIOD_VIEW = [];

export const marketGroupTypes = {
  marketGroup: 'market_group',
  betGroup: 'bet_group',
};

export const DEFAULT_URL_SPORT_NAME = 'Sport';
export const DEFAULT_URL_CATEGORY_NAME = 'Category';
export const DEFAULT_URL_TOURNAMENT_NAME = 'Tournament';
export const DEFAULT_URL_EVENT_NAME = 'Event';

export const MAX_TIMESTAMP = 1800000000000;
export const MIN_TIMESTAMP = 0;

export const ALL_ITEMS = 100000;

export const RACE_STATUS = {
  NOT_OPEN_YET: 'Not Open Yet',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  INTERIM: 'INTERIM',
  PROTEST: 'PROTEST',
  PAYING: 'PAYING',
  ABANDONED: 'ABANDONED',
};

export const MARKET_STATUS = {
  HIDDEN: 'HIDDEN',
  SUSPENDED: 'SUSPENDED',
  OFF: 'OFF',
};

export const FAV_MARKET_ODD_TITLE = 'Our nominated favourite at creation of the market. '
  + 'It will remain unchanged regardless odds movement.';

export const SPECIAL_MARKETS = {
  BEST_T_W: 'BEST_T_W',

  BETTER_T_W: 'BETTER_T_W',
  MID_T_W: 'MID_T_W',
  T5_W: 'T5_W',
  SP: 'SP',
  BT_SP: 'BT_SP',
  TF: 'TF',
  BT_TF: 'BT_TF',
  BEST_T_P: 'BEST_T_P',
  BETTER_T_P: 'BETTER_T_P',
  MID_T_P: 'MID_T_P',
  T5_P: 'T5_P',
};

SPECIAL_MARKETS[RaceLogicMarkets.QUIN] = RaceLogicMarkets.QUIN;
SPECIAL_MARKETS[RaceLogicMarkets.EXACTA] = RaceLogicMarkets.EXACTA;
SPECIAL_MARKETS[RaceLogicMarkets.TRIFECTA] = RaceLogicMarkets.TRIFECTA;
SPECIAL_MARKETS[RaceLogicMarkets.FIRST4] = RaceLogicMarkets.FIRST4;

SPECIAL_MARKETS[RaceMarketsMultilegsTabs.R_DOUBLE] = RaceMarketsMultilegsTabs.R_DOUBLE;
SPECIAL_MARKETS[RaceMarketsMultilegsTabs.D_DOUBLE] = RaceMarketsMultilegsTabs.D_DOUBLE;
SPECIAL_MARKETS[RaceMarketsMultilegsTabs.TREBLE] = RaceMarketsMultilegsTabs.TREBLE;
SPECIAL_MARKETS[RaceMarketsMultilegsTabs.QUADDIE] = RaceMarketsMultilegsTabs.QUADDIE;
SPECIAL_MARKETS[RaceMarketsMultilegsTabs.E_QUADDIE] = RaceMarketsMultilegsTabs.E_QUADDIE;
SPECIAL_MARKETS[RaceMarketsMultilegsTabs.BIG6] = RaceMarketsMultilegsTabs.BIG6;

export const MARKET_PLACES = {};
MARKET_PLACES[RaceLogicMarkets.QUIN] = [1, 2];
MARKET_PLACES[RaceLogicMarkets.EXACTA] = [1, 2];
MARKET_PLACES[RaceLogicMarkets.TRIFECTA] = [1, 2, 3];
MARKET_PLACES[RaceLogicMarkets.FIRST4] = [1, 2, 3, 4];

// used for correct tabs order on Race page
export const RaceMarketsTabsOrder = [
  RaceMarketsTabs.WP_TAB,
  RaceMarketsTabs.SAME_RACE_MULTI,
  RaceMarketsTabs.COMBINED,
  RaceMarketsTabs.TRIFECTA,
  RaceMarketsTabs.FIRST4,
  RaceMarketsTabs.QUIN,
  RaceMarketsTabs.EXACTA,
];

export const RaceExoticGroups = {
  TOTE_EXOTICS: 'TOTE_EXOTICS',
  FIXED_EXOTICS: 'FIXED_EXOTICS',
};

export const SPORTS_BY_CATEGORY = [1, 40, 3]; // soccer, politics and basketball

export const RaceMainTabs = {
  WP_TAB: 'WP_TAB',
  SRM_TAB: 'SRM_TAB',
  EXOTICS_TAB: 'EXOTICS_TAB',
  MULTILEGS_TAB: 'MULTILEGS_TAB',
  COMBINED_TAB: 'COMBINED_TAB',
};

export const RACE_COUNTRIES = {
  local: 'local',
  international: 'international',
};

export const BETSLIP_VALIDATION_ERROR = {
  PENDING_BET: 'OTHER_PENDING_APPROVAL_BET_EXISTS',
};
