import React from 'react';

const SpeedMapItem = ({ runnerStatic, label }) => {
  const { staticInfo } = runnerStatic;
  return staticInfo ? (
    <div className={`speed-map__item speed-map__item--${staticInfo.earlySpeedBand?.toLowerCase()}`}>
      <div className='progress'>
        <div
          className={`progress-bar ${staticInfo.earlySpeedRating ? '' : 'empty'}`}
          style={{
            width: `${staticInfo.earlySpeedRating ? `${staticInfo.earlySpeedRating}%` : ''}`,
          }}
        >
          {label ? (
            <div
              className={`progress-label dot-band  dot-band--${staticInfo.earlySpeedBand?.toLowerCase()}`}
            >
              {label}
            </div>
          ) : null}
        </div>
      </div>
      {staticInfo.earlySpeedBand ? (
        <div
          className={`speed-label speed-label--${staticInfo.earlySpeedBand?.toLowerCase()} dot-band dot-band--${staticInfo.earlySpeedBand?.toLowerCase()}`}
        >
          {staticInfo.earlySpeedBand}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default SpeedMapItem;
