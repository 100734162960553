import React, { useEffect, useRef, useState } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import { DedicatedEventProvider } from '@apollo/core';
import { map } from 'lodash';
import TopEvent from '../../../Sports/TopEvents/TopEvent/TopEvent';

const EventCarousel = ({ events = [] }) => {
  const containerRef = useRef(null);
  const itemsRef = useRef([]);

  const [carouselWidth, setCarouselWidth] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [countSlides, setCountSlides] = useState(0);

  const offsetX = useMotionValue(0);
  const animatedX = useSpring(offsetX, {
    damping: 20,
    stiffness: 150,
  });

  const scrollPrev = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    }
  };

  const scrollNext = () => {
    if (activeSlide < countSlides - 1) {
      setActiveSlide(activeSlide + 1);
    }
  };

  const handleDrag = (event, { offset }) => {
    animatedX.stop();

    if (offset.x > 0) {
      scrollPrev();
    } else if (offset.x < 0) {
      scrollNext();
    }
  };

  const scrollTo = (indexSlide) => {
    if (indexSlide !== activeSlide && indexSlide >= 0) {
      setActiveSlide(indexSlide);
    }
  };

  useEffect(() => {
    const slideWidth = itemsRef.current[activeSlide]
      ? itemsRef.current[activeSlide]?.offsetWidth
      : 0;
    setSlideWidth(slideWidth);

    const carouselWidth = containerRef.current
      ? containerRef.current.scrollWidth
      : slideWidth * countSlides || 0;
    setCarouselWidth(carouselWidth);

    setCountSlides(events.length);

    scrollTo(activeSlide);
  }, [containerRef, events.length]);

  useEffect(() => {
    console.log('Slide move to :', activeSlide);
    animatedX.stop();
    offsetX.set(-activeSlide * slideWidth);
  }, [activeSlide, slideWidth]);

  return (
    <div className='carousel-wrapper' style={{ overflowX: 'hidden', width: '100%' }}>
      <div
        className='carousel'
        style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: slideWidth }}
      >
        <motion.div
          ref={containerRef}
          className='carousel-track'
          style={{
            display: 'flex',
            flexDirection: 'row',
            x: animatedX,
          }}
          drag='x'
          dragConstraints={{
            left: -carouselWidth + slideWidth,
            right: 0,
          }}
          dragElastic={0}
          dragSnapToOrigin
          onDragEnd={handleDrag}
        >
          {map(events, (event, index) => (
            <motion.div
              key={event.id}
              custom={index}
              className='carousel__item'
              ref={(item) => (itemsRef.current[index] = item)}
              animate={{ scale: index === activeSlide ? 1.0 : 0.8 }}
              transition={{
                ease: 'linear',
                duration: 0.3,
              }}
            >
              <DedicatedEventProvider event={event} key={event.id}>
                <TopEvent className='sport-events__list-item' />
              </DedicatedEventProvider>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default EventCarousel;
