import React, { useMemo } from 'react';
import { isEmpty, map } from 'lodash';
import RaceRunner from '../RaceRunner';
import { ordinalSuffixOf } from '../../../../core/utils';
import Odds from '../../../../shared/components/Odds/Odds';
import useTranslate from '../../../../shared/components/I18n/Interpreter';

const RaceResultsTable = ({ activeRace }) => {
  const t = useTranslate();
  const runnersByNumber = useMemo(
    () => activeRace?.runners.reduce((acc, runner) => ({ ...acc, [runner.number]: runner }), {}),
    [activeRace?.runners],
  );

  const topRunners = activeRace?.uiResults?.topRunners ?? [];

  const runners = topRunners?.map((runner) => ({
    ...runnersByNumber?.[runner.runnerNumber],
    ...runner,
  }));

  const fWinOdds = useMemo(() => (
    activeRace?.markets
      ?.find(({ type }) => type === 'F_WIN')
      ?.outcomes
      ?.reduce((result, { selection, odds }) => ({
        ...result,
        [selection]: odds,
      }), {})
  ), [activeRace?.markets]);

  const fPlcOdds = useMemo(() => (
    activeRace?.markets
      ?.find(({ type }) => type === 'F_PLC')
      ?.outcomes
      ?.reduce((result, { selection, odds }) => ({
        ...result,
        [selection]: odds,
      }), {})
  ), [activeRace?.markets]);

  return (
    <div className='event-group event-group--races'>
      {isEmpty(runners) === false && map(runners, (r) => (
        <div
          key={`${r.runnerId}-${r.finishPosition}`}
          className={`event event--classic ${r.scratched ? 'event--scratched' : ''}`}
        >
          <div className='event__main-period'>
            <span className='runnerFinishPosition'>{ordinalSuffixOf(r.finishPosition)}</span>
            <RaceRunner runner={r} activeRace={activeRace} showRunnerImage />
            <div className='event__market-row'>
              <div className='event__market-row-market event__market-row--race-dividend'>
                <If condition={r.finishPosition === 1}>
                  <div className='result-with-label'>
                    <div className='label'>{t('win')}</div>
                    <div className='results-odds'>
                      <Odds value={fWinOdds[r.number]} />
                    </div>
                  </div>
                </If>
              </div>
              <div className='event__market-row-market event__market-row--race-dividend'>
                <If condition={r.finishPosition <= activeRace.numPlaces}>
                  <div className='result-with-label'>
                    <div className='label'>{t('place')}</div>
                    <div className='results-odds'>
                      <Odds value={fPlcOdds[r.number]} />
                    </div>
                  </div>
                </If>
                <If condition={r.finishPosition > activeRace.numPlaces && r.finishPosition < 4}>
                  <div>
                    {`No ${ordinalSuffixOf(r.finishPosition)} dividend`}
                  </div>
                </If>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RaceResultsTable;
