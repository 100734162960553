import React from 'react';
import cx from 'classnames';
import { TournamentLink } from '../../../../shared/components/Sport/SportLinks';

const internationalId1 = 329;
const internationalId2 = 439;
const internationalId3 = 842;

const internationalIds = [internationalId1, internationalId2, internationalId3];

const TournamentsListItem = ({ tournament, active }) => {
  const { id, name, sportId, sportName, urlName, sportUrlName, categoryUrlName, totalCount } = tournament;

  // const { selectFavoriteTournament, getIsTournamentFavorite } = usePreferences();
  // const isTournamentFavorite = getIsTournamentFavorite({ tournamentId: tournament.id });

  /* const setFavoriteTournamentHandler = useCallback(() => {
    selectFavoriteTournament({ tournamentId: tournament.id });
  }, [tournament, selectFavoriteTournament]); */

  const className = cx('sidebar-tree__section-item sidebar-tree__section-item--tournament', {
    active,
  });

  return (
    <TournamentLink
      sportUrlName={sportUrlName}
      categoryUrlName={categoryUrlName}
      tournamentUrlName={urlName}
      className={className}
    >
      <i
        className={`icon sportIcon sportIcon-${sportId} ${name.toLowerCase().split(' ').join('-')}`}
        title={sportName}
      />

      <span className='name' title={id}>
        {name}
        {/* <sup>{` (${totalCount})`}</sup> */}
      </span>

      {/* {
            authenticated && (
              <IconFavorite
                isActive={isTournamentFavorite}
                onClick={setFavoriteTournamentHandler}
              />
            )
          } */}
    </TournamentLink>
  );
};

export default TournamentsListItem;
