import {
  chunk,
  forEach,
  get,
  groupBy,
  isArray,
  isString,
  max,
  range,
  remove,
  sortBy,
  split,
} from 'lodash';
import { constants, getOutcomeSortingFunction, isFeedOffline, isSuspended } from '@apollo/core';
import {
  diapasonSort,
  handleSpecifierWithDivider,
  numGoalSort,
  oddsSorting,
  outcomeNameParser,
  sortBySpec,
  specSortMarket,
} from './dataParserUtils';
import { getDisplayLayoutById } from './mappingObject';

const { DISPLAY_LAYOUTS } = constants;

// DTO example
// const marketTypeDataType = {
//   title: 'MarketTypeTitle',
//   header: [] || null,
//   type: null,
//   rows: [[], []],
// };

const totalPlayerAssistIds = [312, 308, 310, 314, 316, 318, 320, 332, 336, 338, 340];
const threeWay = [
  2562, 412, 18, 58, 1906, 2627, 366, 2107, 784, 930, 2427, 2008, 1758, 1625, 1842, 2668, 1918,
  1609,
];

const rowSorting = ({ rows, event, isGameToDeuse }) => {
  const {
    participants: { home, away },
  } = event;

  let columnsCount = 0;
  forEach(rows, (row) => {
    if (columnsCount < row.length) columnsCount = row.length;
  });
  const newRows = [];

  forEach(rows, (row, index) => {
    const sortedRow = [];
    forEach(row, (o) => {
      if (!sortedRow[0]) {
        const name = get(o, 'param');
        sortedRow[0] = { name, isFake: true, key: `${name}${index}` };
      }
      if (isGameToDeuse) sortedRow[o.type === 'Yes' ? 1 : 2] = o;
      // else if (!o.name || !o.type) debugger;
      else if (o.name.includes(home?.fullName) || o.type.includes('participant1')) sortedRow[1] = o;
      else if (o.name.includes(away?.fullName) || o.type.includes('participant2')) sortedRow[columnsCount] = o;
      else columnsCount === 2 ? (sortedRow[1] = o) : (sortedRow[2] = o);
      o.name = '';
    });
    newRows[index] = sortedRow;
  });

  return { sortedRows: newRows, columnsCount };
};

const cricketSeparatorParser = ({ orderedOutcomes, marketType: { id: marketTypeId } }) => {
  const groupedBySpec = groupBy(orderedOutcomes, 'specifiers');
  const isTotalPlayerAssist = totalPlayerAssistIds.includes(marketTypeId);
  const twoNumbersInSpecifiers = [3372, 3356, 3355, 3374, 3375, 3376, 3377].includes(marketTypeId);
  let header = null;
  const rows = sortBySpec(Object.values(groupedBySpec));

  forEach(rows, (arr, rowIndex) => {
    const arrayWithCorrectOutcomesPosition = [...arr];
    forEach(arr, (o, index) => {
      const newOutcome = { ...o };
      if (twoNumbersInSpecifiers) {
        // two number in specifiers
        const paramsArr = split(o.specifiers, '/', 2);
        newOutcome.name = `${split(o.name, ' ', 1)[0]} ${paramsArr[1]}`;
        newOutcome.param = `Over ${paramsArr[0]}`;
      } else if (isTotalPlayerAssist) {
        // Total Player Assists
        header = [null, 'Over', 'Under'];
        const paramsArr = split(o.specifiers, '/', 2);
        newOutcome.name = paramsArr[1];
        newOutcome.param = paramsArr[0];

        if (newOutcome.type.includes('Over')) return (arrayWithCorrectOutcomesPosition[0] = newOutcome);
        return (arrayWithCorrectOutcomesPosition[1] = newOutcome);
      } else {
        newOutcome.param = `Over ${parseInt(o.specifiers)}`;
      }

      arrayWithCorrectOutcomesPosition[index] = newOutcome;
    });
    const name = arrayWithCorrectOutcomesPosition[0].param;
    arrayWithCorrectOutcomesPosition.unshift({
      name,
      key: `${name}-${rowIndex}`,
      isFake: true,
      className: 'verticalHeader',
    });
    rows[rowIndex] = arrayWithCorrectOutcomesPosition;
  });

  if (isTotalPlayerAssist) rows.reverse();

  return { rows, header };
};

const correctScoreParser = ({ orderedOutcomes, marketType: { displayLayout } }) => {
  const firstColumn = [];
  const secondColumn = [];
  const thirdColumn = [];
  let outcomesColumnCount = 0;

  forEach(orderedOutcomes, (o) => {
    if (o.specifiers) {
      const scoreArr = o.specifiers.split(':');

      if (scoreArr?.length) {
        if (scoreArr[0] > scoreArr[1]) {
          firstColumn.push({
            ...o,
            sortField: `${scoreArr[0]}${scoreArr[1]}`,
          });
        } else if (scoreArr[0] < scoreArr[1]) {
          thirdColumn.push({
            ...o,
            sortField: `${scoreArr[1]}${scoreArr[0]}`,
          });
        } else {
          secondColumn.push({ ...o, sortField: `${scoreArr[0]}${scoreArr[1]}` });
        }
      }
    }
  });

  const result = (
    displayLayout === DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED
      ? [firstColumn, thirdColumn]
      : [firstColumn, secondColumn, thirdColumn]
  ).map((arr) => {
    if (firstColumn.length > outcomesColumnCount) outcomesColumnCount = firstColumn.length;
    return sortBy(arr, [(o) => parseInt(o.sortField)]);
  });

  result.forEach((arr) => {
    if (arr.length > outcomesColumnCount) outcomesColumnCount = arr.length;
  });

  result.forEach((arr) => {
    const count = outcomesColumnCount - arr.length;
    if (arr.length < outcomesColumnCount) {
      for (let i = 0; i < count; i++) {
        arr.push({ key: i, isFake: true });
      }
    }
  });

  const rows = [];

  for (let i = 0; i < result[0].length; i++) {
    const row = [];

    for (let j = 0; j < result.length; j++) {
      row.push(result[j][i]);
    }
    rows.push(row);
  }
  return { rows };
};

const winnerScoreParser = (drawOption, { orderedOutcomes }) => {
  const numColumns = drawOption ? 3 : 2;
  const columns = orderedOutcomes
    // First consider only outcomes with position 1 (home), 2 (draw), (3 away) or 1 (home), (2 away)
    .filter((o) => o.orderPosition <= numColumns)
    // Distribute outcomes by columns
    .reduce(
      (columns, o) => {
        columns[o.orderPosition - 1].push(o);
        return columns;
      },
      drawOption ? [[], [], []] : [[], []],
    )
    // Sort each column by specifier
    .map((column) => sortBy(column, (o) => {
      let { specifiers } = o;
      if (isString(specifiers)) {
        specifiers = specifiers.replaceAll('-', ''); // if 3-1 or 1-5
      }
      return Number.isNaN(parseFloat(specifiers)) ? specifiers : parseFloat(specifiers);
    }));

  const rows = [];
  const rowsCount = max(columns.map((c) => c.length));

  if (drawOption) {
    // There may not be draw outcomes at all
    const emptyDraw = columns[1].length === 0;
    // Using fake outcomes to keep outcomes in their columns
    range(rowsCount).forEach((i) => rows.push([
      columns[0][i] || { isFake: true, id: `fake-0-${i}` },
      columns[1][i] || (emptyDraw ? {} : { isFake: true, id: `fake-1-${i}` }),
      columns[2][i] || { isFake: true, id: `fake-2-${i}` },
    ]));
  } else {
    range(rowsCount).forEach((i) => rows.push([
      columns[0][i] || { isFake: true, id: `fake-0-${i}` },
      columns[1][i] || { isFake: true, id: `fake-1-${i}` },
    ]));
  }

  if (rows.length > 0) {
    return { rows };
  }

  // Outcomes in format x:x can still be presented in the market, just add to the end
  return correctScoreParser({
    orderedOutcomes: orderedOutcomes.filter((o) => o.orderPosition > numColumns),
    marketType: { displayLayout: DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED },
  });
};

const winningMarginParser = ({
  event,
  marketType,
  marketType: { displayLayout },
  orderedOutcomes,
}) => {
  const isPointWinner = displayLayout === DISPLAY_LAYOUTS.POINT_WINNER;
  const isNextGoal = displayLayout === DISPLAY_LAYOUTS.NEXT_GOAL || DISPLAY_LAYOUTS.RACE_TO;
  const isRaceTo = displayLayout === DISPLAY_LAYOUTS.RACE_TO;
  const isGameToDeuse = displayLayout === DISPLAY_LAYOUTS.GAME_TO_DEUCE;
  const isGameScore = displayLayout === DISPLAY_LAYOUTS.GAME_SCORE; // добавляет to перед Pt всё...
  let isDiapason = false;
  const {
    participants: { home, away },
  } = event;

  const data = {};
  const result = [];

  // adding param for grouping to outcomes
  forEach(orderedOutcomes, (o) => {
    let param;
    if (!isDiapason && o.specifiers && o.specifiers.includes('-')) isDiapason = true;
    if (isPointWinner || isGameScore) param = outcomeNameParser({ outcome: o, sportId: event.sportId, marketType });
    else if (isNextGoal) param = parseInt(o.specifiers);
    else if (isGameToDeuse) param = outcomeNameParser({ outcome: o, sportId: event.sportId, marketType });
    else if (o.type.includes('{specifier}+') && !o.specifiers.includes('+')) {
      param = `${o.specifiers}+`;
    } else {
      param = o.specifiers;
    }
    result.push({ ...o, param: `${param}`.trim() });
  });

  // grouping by param
  if (isPointWinner || isGameScore) {
    const groupedBySpec = groupBy(result, 'specifiers'); // we can't group by spec at all case
    data.rows = sortBySpec(Object.values(groupedBySpec)); // sortBySpec => sort array of outcome arrays by specs 4/5/6
  } else {
    data.rows = groupBy(result, 'param');

    if (isDiapason) {
      data.rows = Object.values(data.rows).sort(diapasonSort);
    }
  }

  if (!isArray(data.rows)) data.rows = Object.values(data.rows);

  // set outcomes to correct column and adding first column with param
  const { sortedRows, columnsCount } = rowSorting({ rows: data.rows, event, isGameToDeuse });
  data.rows = sortedRows;

  if (isNextGoal && columnsCount !== 2) {
    data.header = [false, home?.fullName, isRaceTo ? 'Neither' : 'No Goal', away?.fullName];
  } else if (isGameToDeuse) data.header = [false, 'Yes', 'No'];
  else data.header = [false, home?.fullName, away?.fullName];

  forEach(data.rows, (row) => {
    row[0].className = 'verticalHeader';
  });

  return data;
};

const handicapParser = ({ sortedFilteredMarkets: markets, marketType: { id } }) => {
  // market specifiers came with opposite value not -1.0 but +1.0 where home team outcome has -1.0
  const sortedMarkets = sortBy(markets, [(market) => handleSpecifierWithDivider(market)]);
  const outcomes = [];
  const columnsAmount = threeWay.includes(id) ? 3 : 2;

  forEach(sortedMarkets, (m) => {
    for (let i = 0; i < columnsAmount; i++) {
      if (m.outcomes[i]) {
        outcomes.push(m.outcomes[i]);
      } else {
        outcomes.push({ isFake: true, key: `grid-fake-${i}` });
      }
    }
  });
  return {
    rows: chunk(outcomes.reverse(), threeWay.includes(id) ? 3 : 2).map((arr) => arr.reverse()),
  };
};

const numGoalParser = ({ sortedFilteredMarkets: markets }) => {
  const outcomes = [];
  forEach(markets, (m) => forEach(m.outcomes, (o) => outcomes.push(o)));
  return { rows: chunk(outcomes.sort(numGoalSort), 3) };
};

export const marketSpecifierSorted = ({ sortedFilteredMarkets: markets }) => {
  // split by markets and sort by specifiers in each market.

  const outcomes = [];
  forEach(markets, (m) => {
    const sortedOutcomes = [...m.outcomes].sort((a, b) => {
      // sort by market specifiers home/away
      if (isString(a.specifiers) && isString(b.specifiers)) {
        if (a.specifiers < b.specifiers) return -1;
        if (a.specifiers > b.specifiers) return 1;
        return 0;
      }
      return 0;
    });
    forEach(sortedOutcomes, (o, oIndex) => {
      outcomes.push(o);
    });
  });
  return { rows: chunk(outcomes, 2) };
};

export const marketsOddsSorting = ({ sortedFilteredMarkets: markets }) => {
  // split by markets and sort by odds in each market.
  const outcomes = [];
  forEach(markets, (m, mIndex) => {
    const sortedOutcomes = [...m.outcomes].sort(oddsSorting);
    forEach(sortedOutcomes, (o, oIndex) => {
      outcomes[oIndex * markets.length + mIndex] = o;
    });
  });
  return { rows: chunk(outcomes, markets.length) };
};

const sortColumnsWithDraw = (outcomeBlockItems) => {
  try {
    // or shortName?..
    const home = remove(outcomeBlockItems, ({ type }) => type?.includes('participant1'));
    const away = remove(outcomeBlockItems, ({ type }) => type?.includes('participant2'));
    const draw = remove(outcomeBlockItems, ({ type }) => type?.includes('Draw'));

    return [...home, ...draw, ...outcomeBlockItems, ...away];
  } catch (e) {
    return outcomeBlockItems;
  }
};

const groupBySpecifiers = (orderedOutcomes, columnsAmount = 3) => {
  const grouped = Object.values(groupBy(orderedOutcomes, 'specifiers'));
  return grouped
    .map((outcomesArr, i) => {
      if (outcomesArr?.length < columnsAmount) {
        return [...outcomesArr, { isFake: true, key: `grid-fake-${i}` }];
      }
      return outcomesArr;
    })
    .flat();
};

const columnsParser = (displayLayout, orderedOutcomes) => {
  const data = {
    rows: [],
  };

  let sortedOutcomes = orderedOutcomes;

  if (['odds_sorting_two_columns', 'odds_sorting_three_columns'].includes(displayLayout)) {
    sortedOutcomes = orderedOutcomes.sort(oddsSorting);
  } else if (['spec_desc_two_columns_sorted'].includes(displayLayout)) {
    sortedOutcomes = sortBy(orderedOutcomes, [(_) => specSortMarket(_, false)]);
  } else if (['three_columns_grouped_by_specifiers'].includes(displayLayout)) {
    // three_columns_grouped_by_specifiers fixes incomplete rows by adding isFake items
    sortedOutcomes = groupBySpecifiers(orderedOutcomes);
  }

  const isOneColumn = displayLayout === DISPLAY_LAYOUTS.ONE_COLUMN;
  const isThreeColumns = (orderedOutcomes.length === 3 && displayLayout !== DISPLAY_LAYOUTS.TWO_COLUMNS)
    || [
      DISPLAY_LAYOUTS.THREE_COLUMNS_GROUPED_BY_SPECIFIERS,
      DISPLAY_LAYOUTS.THREE_COLUMNS,
      DISPLAY_LAYOUTS.ODDS_SORTING_THREE_COLUMNS,
    ].includes(displayLayout);
  if (isOneColumn) {
    data.rows = chunk(sortedOutcomes, 1);
  } else if (isThreeColumns) {
    data.rows = chunk(sortedOutcomes, 3).map(sortColumnsWithDraw);
  } else {
    data.rows = chunk(sortedOutcomes, 2); // default
  }

  return data;
};

export const marketTypeParser = ({ event, marketType, feedStates }) => {
  if (!marketType || !marketType.filteredMarkets?.length) {
    return null;
  }

  const sortedFilteredMarkets = sortBy(marketType.filteredMarkets, [specSortMarket]);

  const orderedOutcomes = [];
  forEach(sortedFilteredMarkets, (market) => {
    const isSuspendedMarket = isSuspended(market)
      || isSuspended(event)
      || isFeedOffline(feedStates[marketType.providerName]);

    market.outcomes.sort(getOutcomeSortingFunction()).forEach((outcome) => {
      const o = { ...outcome };
      if (isSuspendedMarket) {
        o.status = constants.STATUS.SUSPENDED;
      }
      orderedOutcomes.push(o);
    });
  });

  const displayLayout = marketType.displayLayout || getDisplayLayoutById(marketType.id);

  // This looks wrong. Why do we do this?
  marketType.displayLayout = displayLayout;

  let data;
  try {
    if (
      displayLayout === DISPLAY_LAYOUTS.HANDICAP
      || displayLayout === DISPLAY_LAYOUTS.HANDICAP_DROPDOWN
    ) {
      data = handicapParser({
        event,
        marketType,
        orderedOutcomes,
        sortedFilteredMarkets,
      });
    } else if (
      [
        DISPLAY_LAYOUTS.TWO_COLUMNS_SCORE_SORTED,
        DISPLAY_LAYOUTS.THREE_COLUMNS_SCORE_SORTED,
      ].includes(displayLayout)
    ) {
      data = correctScoreParser({
        event,
        marketType,
        orderedOutcomes,
        sortedFilteredMarkets,
      });
    } else if (displayLayout === DISPLAY_LAYOUTS.TWO_COLUMNS_WINNER_SCORE) {
      data = winnerScoreParser(false, {
        event,
        marketType,
        orderedOutcomes,
        sortedFilteredMarkets,
      });
    } else if (displayLayout === DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE) {
      data = winnerScoreParser(true, {
        event,
        marketType,
        orderedOutcomes,
        sortedFilteredMarkets,
      });
    } else if (displayLayout === DISPLAY_LAYOUTS.NUMBER_OF_GOALS) {
      data = numGoalParser({
        event,
        marketType,
        orderedOutcomes,
        sortedFilteredMarkets,
      });
    } else if (displayLayout === DISPLAY_LAYOUTS.CRICKET_SEPARATOR) {
      data = cricketSeparatorParser({
        event,
        marketType,
        orderedOutcomes,
        sortedFilteredMarkets,
      });
    } else if (displayLayout === DISPLAY_LAYOUTS.ODDS_SORTING_SPLIT_BY_MARKET) {
      data = {
        subs: [],
      };

      marketType?.filteredMarkets?.forEach((market) => {
        // cricket high bat

        const sortedOutcomes = [...market.outcomes].sort(oddsSorting) || [];
        data.rows = chunk(sortedOutcomes, 2); // default

        forEach(data.rows, (row, index) => {
          for (let i = 0; i < 2; i++) {
            if (!row[i]) {
              row[i] = { isFake: true, key: `grid-fake-${index}-${i}` };
            }
          }
        });

        const marketData = {
          rows: data.rows || [],
          header: [market.specifiers?.split('#')[0]],
        };

        data.subs.push(marketData);
      });
    } else if (displayLayout === DISPLAY_LAYOUTS.ODDS_SORTING_GROUPED_BY_MARKET) {
      sortedFilteredMarkets.sort((a, b) => {
        // sort by market specifiers home/away
        if (
          isString(a.specifiers)
          && isString(event.participants?.home?.fullName)
          && isString(event.participants?.away?.fullName)
        ) {
          if (
            event.participants.home.fullName.toLowerCase().startsWith(a.specifiers.toLowerCase())
          ) {
            return -1;
          }
          if (
            event.participants.away.fullName.toLowerCase().startsWith(a.specifiers.toLowerCase())
          ) {
            return 1;
          }
          // it can be '1' and '2' instead of names
          if (a.specifiers < b.specifiers) return -1;
          if (a.specifiers > b.specifiers) return 1;
          return 0;
        }
        return 0;
      });
      data = marketsOddsSorting({ orderedOutcomes, sortedFilteredMarkets });
    } else if (
      [
        DISPLAY_LAYOUTS.POINT_WINNER,
        DISPLAY_LAYOUTS.NEXT_GOAL,
        DISPLAY_LAYOUTS.RACE_TO,
        DISPLAY_LAYOUTS.GAME_TO_DEUCE,
        DISPLAY_LAYOUTS.CRICKET_SEPARATOR,
        DISPLAY_LAYOUTS.GAME_SCORE,
        DISPLAY_LAYOUTS.WINNING_MARGIN,
      ].includes(displayLayout)
    ) {
      data = winningMarginParser({
        event,
        marketType,
        orderedOutcomes,
        sortedFilteredMarkets,
      });
    } else if (displayLayout === DISPLAY_LAYOUTS.TWO_COLUMNS_GROUPED_BY_MARKET_SPECIFIER_SORTED) {
      data = marketSpecifierSorted({ orderedOutcomes, sortedFilteredMarkets });
    } else {
      // 'one_column', 'two_columns', 'three_columns', 'odds_sorting_two_columns', 'odds_sorting_three_columns'
      data = columnsParser(displayLayout, orderedOutcomes);
    }
  } catch (e) {
    data = columnsParser(displayLayout, orderedOutcomes);
  }

  if (!data) {
    return null;
  }

  if (!data.title) data.title = marketType.name;
  if (data.header === undefined) data.header = null;
  if (data.displayLayout === undefined) data.type = displayLayout;

  // for grid
  if (!data.subs) {
    const columnsLength = data.header?.length || data.rows[0]?.length || 0;

    forEach(data.rows, (row, index) => {
      for (let i = 0; i < columnsLength; i++) {
        if (!row[i]) row[i] = { isFake: true, key: `grid-fake-${index}-${i}` };
      }
    });
  }

  return data;
};
