import React from 'react';
import { get, includes, map } from 'lodash';
import { Bus, getAvailablePages, SiteConfigManager, useApplicationState } from '@apollo/core';
import { motion, AnimatePresence } from 'framer-motion';
import { COMPONENT_TYPES } from '@apollo/routing';
import HeaderMenuItem from './HeaderMenuItem';
import HeaderInfoAuthorized from '../HeaderStatusBlock/HeaderInfoAuthorized';
import { cmsNavBlock } from '../../../../core/constants';
import Label from '../../I18n/Label';
import LiveChatLink from '../../LiveChatLink/LiveChatLink';
import ResponsibleGamblingPolicyLink from '../../ResponsibleGamblingPolicyLink/ResponsibleGamblingPolicyLink';

const HeaderMenuList = () => {
  const { authenticated, layout } = useApplicationState();

  const authProtectedRoutes = [];

  const racingRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.RACING);
  const sportRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_PREMATCH);
  const liveRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_LIVE);
  const casinoRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.CASINO);
  const resultsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.RESULTS);
  const tvGamesRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.TV_GAMES);
  const liveCasinoRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.CASINO_LIVE);
  const promotionsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.PROMOTIONS);
  const promotionTournamentRoute = SiteConfigManager.getComponentPath(
    COMPONENT_TYPES.PROMOTION_TOURNAMENTS,
  );

  const toggleMobileMenu = React.useCallback(() => {
    Bus.send({
      event: Bus.events.layout.setMainMenu,
      data: !layout.mainMenu,
    });
  }, [Bus, layout]);

  const menuItems = getAvailablePages(
    {
      [COMPONENT_TYPES.RACING]: {
        name: 'Racing',
        link: racingRoute,
      },
      [COMPONENT_TYPES.SPORT_PREMATCH]: {
        name: 'Sport',
        link: sportRoute,
      },
      [COMPONENT_TYPES.SPORT_LIVE]: {
        name: 'Live',
        link: liveRoute,
      },
      [COMPONENT_TYPES.CASINO]: {
        name: 'Casino',
        link: casinoRoute,
      },
      [COMPONENT_TYPES.RESULTS]: {
        name: 'Results',
        link: resultsRoute,
        className: 'd-md-none',
      },
      [COMPONENT_TYPES.TV_GAMES]: {
        name: 'TV Games',
        link: tvGamesRoute,
      },
      [COMPONENT_TYPES.CASINO_LIVE]: {
        name: 'LIVE CASINO',
        link: liveCasinoRoute,
      },
      [COMPONENT_TYPES.PROMOTIONS]: {
        name: 'PROMOTIONS',
        link: promotionsRoute,
      },
      [COMPONENT_TYPES.PROMOTION_TOURNAMENTS]: {
        name: 'PROMOTION_TOURNAMENTS',
        link: promotionTournamentRoute,
      },
    },
    SiteConfigManager.getExtraConfig('components'),
  );

  const helpNav = get(SiteConfigManager.navigation, cmsNavBlock.Footer);

  return (
    <AnimatePresence>
      {(!layout.mobileDevice || layout.mainMenu) && (
        <motion.div
          className={`header__menu ${layout.mainMenu ? 'header__menu--active' : ''}`}
          initial={layout.mobileDevice ? { x: 300, opacity: 0 } : { x: 0, opacity: 1 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 300, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {!layout.mobileDevice && (
            <>
              <div className='header__menu__title'>
                <Label message='Racing & sport' />
              </div>
              {map(menuItems, ({ link, name, className }) => (
                <HeaderMenuItem
                  key={name}
                  label={name}
                  link={link}
                  className={className}
                  authRequired={includes(authProtectedRoutes, link)}
                />
              ))}
            </>
          )}

          {authenticated && <HeaderInfoAuthorized expanded />}

          <div className='header__menu__items'>
            <div className='header__menu__title'>
              <Label message='Help' />
            </div>
            <LiveChatLink className='header__menu__item' />
            {helpNav
              && helpNav[1]?.children?.map((link) => (
                <HeaderMenuItem key={link.id} label={link.name} link={link.location} />
              ))}
            <ResponsibleGamblingPolicyLink className='header__menu__item responsible-gambling' />
          </div>

          {layout.mobileDevice && (
            <div
              onClick={toggleMobileMenu}
              className='header__menu__item header__menu__item--close'
            >
              <i className='icons icon--close-btn-red' />
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HeaderMenuList;
