import React from 'react';
import { SiteConfigManager } from '@apollo/core';
import { NavLink } from 'react-router-dom';
import { COMPONENT_TYPES } from '@apollo/routing';
import Label from '../I18n/Label';
import useTranslate from '../I18n/Interpreter';
import { isPromotionsActive } from '../../../core/utils';

const SidebarHomeLinks = ({ onClickLinkHandler }) => {
  const t = useTranslate();

  const promotionsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.PROMOTIONS);
  const promotionsActive = isPromotionsActive();

  return (
    <>
      <div className='sidebar-tree__section-title sidebar-tree__section-title--home'>
        <NavLink
          to='/'
          exact
          className='sidebar-tree__section-title--link'
          onClick={onClickLinkHandler}
        >
          <i className='icons icon--home' title={t('Home')} />
          <Label message='Home' className='name' />
        </NavLink>
      </div>

      {promotionsActive && (
        <div className='sidebar-tree__section-title sidebar-tree__section-title--home'>
          <NavLink
            to={promotionsRoute}
            exact
            className='sidebar-tree__section-title--link'
            onClick={onClickLinkHandler}
          >
            <i className='icons icon--bet-bonus-all' title={t('Promotions')} />
            <Label message='Promotions' className='name' />
          </NavLink>
        </div>
      )}
    </>
  );
};

export default SidebarHomeLinks;
