import _, {
  at,
  capitalize,
  chain,
  each,
  find,
  get,
  identity,
  includes,
  isEmpty,
  isNull,
  isObject,
  isString,
  lowerCase,
  mapValues,
  minBy,
  noop,
  orderBy,
  snakeCase,
  sortBy,
  toArray,
  toNumber,
  uniq,
  upperFirst,
} from 'lodash';
import moment from 'moment';
import {
  Bus,
  centsToDecimal,
  DateService,
  DateUtils,
  getBetOutcome,
  getSportMainMarketTypesByPeriod,
  isExoticMarket,
  isMultilegsMarket,
  isVisible,
  SiteConfigManager,
} from '@apollo/core';
import React from 'react';
import {
  BET_EVENT_TYPE,
  DISPLAY_LAYOUTS,
  RaceLogicMarkets,
  RaceTypesList,
  RaceTypesMap,
  SRM_MARKETS_LIST,
} from '@apollo/core/src/constants';
import { generatePath } from 'react-router-dom';
import { COMPONENT_TYPES } from '@apollo/routing';
import * as preferences from './preferencesUtils';
import {
  betTypes,
  currencySymbols,
  DEFAULT_URL_CATEGORY_NAME,
  DEFAULT_URL_EVENT_NAME,
  DEFAULT_URL_SPORT_NAME,
  DEFAULT_URL_TOURNAMENT_NAME,
  MAX_TIMESTAMP,
  MIN_TIMESTAMP,
  outcomeStatuses,
  RACE_STATUS,
  SPECIAL_MARKETS,
  specialDates,
  sportService,
} from './constants';
import ToastManager from './ToastManager';
import Label from '../shared/components/I18n/Label';

export const { getSportsWithTournamentsByPreferences } = preferences;
export const { getEventsByPreferences } = preferences;

const { PREMATCH, LIVE, ARCHIVED, UNSPECIFIED } = sportService;

const { SINGLE } = betTypes;

export const compareVersions = (currentVer, minimumVer) => {
  const currentParts = (currentVer || '1.0.0').split('.');
  const minimumParts = (minimumVer || '1.0.0').split('.');
  for (let i = 0; i < minimumParts.length; i += 1) {
    const a = parseInt(currentParts[i]);
    const b = parseInt(minimumParts[i]);
    if (a > b) return true;
    if (a < b) return false;
  }
  return true;
};

export const safeJSONparse = (str) => {
  if (isEmpty(str)) {
    return {};
  }
  try {
    return JSON.parse(str);
  } catch (error) {
    return {};
  }
};

export const getReadableString = (string) => upperFirst(chain(string).split('_').join(' ').lowerCase());

export const cutFractionalNumber = (value) => {
  const number = toNumber(value);
  return (Math.floor(number * 100) / 100).toFixed(2);
};

// returns tournament name if all bets are related to the same tournament
// otherwise returns the general name
export const getMultipleTournamentName = (bet) => {
  let tournament = '';

  each(bet.outcomes, ({ outcomeInfo: { tournamentName } }) => {
    if (!tournament) {
      tournament = tournamentName;
      return;
    }
    if (tournament !== tournamentName) {
      tournament = upperFirst(lowerCase(bet.betType));
    }
  });
  return tournament;
};

const uriPattern = /^https?:\/\//i;
export const isUrlAbsolute = (url) => uriPattern.test(url);

export const getSportServiceStatus = (bet) => {
  let isLive = false;
  let isPrematch = false;
  let isArchived = false;
  let isUnspecified = false;
  if (bet.betType === SINGLE) {
    if (bet.outcome.outcomeInfo.sportService === LIVE) {
      isLive = true;
    }
    if (bet.outcome.outcomeInfo.sportService === PREMATCH) {
      isPrematch = true;
    }
    if (bet.outcome.outcomeInfo.sportService === ARCHIVED) {
      isArchived = true;
    }
    if (bet.outcome.outcomeInfo.sportService === UNSPECIFIED) {
      isUnspecified = true;
    }
  }
  if (bet.betType !== SINGLE) {
    each(bet.outcomes, ({ outcomeInfo: { sportService } }) => {
      if (sportService === LIVE) {
        isLive = true;
      }
      if (sportService === PREMATCH) {
        isPrematch = true;
      }
      if (sportService === ARCHIVED) {
        isArchived = true;
      }
      if (sportService === UNSPECIFIED) {
        isUnspecified = true;
      }
    });
  }
  return {
    isLive,
    isPrematch,
    isArchived,
    isUnspecified,
  };
};

export const compare = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const compareByParam = (param) => (a, b) => compare(a[param], b[param]);

export const compareReverse = (a, b) => -1 * compare(a, b);

export const compareByParamReverse = (param) => (a, b) => compareReverse(a[param], b[param]);

// export const centsToDecimal = (amount, currency, fix = true) => {
//   let processedAmount = amount;
//   if (!processedAmount) {
//     processedAmount = 0;
//   }
//   processedAmount = Number(processedAmount) / 100;
//   if (fix) {
//     return `${processedAmount.toFixed(2)}${currency ? ` ${String(currency || '')}` : ''}`;
//   }
//   return processedAmount;
// };

export const getAvailablePages = (availablePages, pagesToMatch) => {
  const tabs = [];

  each(pagesToMatch, (item) => {
    const { type, isActive } = item;
    const page = availablePages[type];

    if (page && isActive) {
      tabs.push({
        ...item,
        ...page,
      });
    }
  });

  return tabs;
};

export const isPromotionsActive = () => {
  const components = SiteConfigManager.getExtraConfig('components');
  const pages = getAvailablePages(
    {
      [COMPONENT_TYPES.PROMOTIONS]: {
        name: 'PROMOTIONS',
        link: SiteConfigManager.getComponentPath(COMPONENT_TYPES.PROMOTIONS),
      },
    },
    components,
  );
  return !isEmpty(pages);
};

//
// NOTE: Use lodash debounce
//
// export const debounce = (func, wait, funcArguments) => {
//   let timeout;
//
//   return () => {
//     clearTimeout(timeout);
//
//     timeout = setTimeout(() => {
//       timeout = null;
//       if (funcArguments) {
//         func(funcArguments);
//       } else {
//         func.apply(this, { });
//       }
//     }, wait);
//   };
// };

export const getIsFieldRequired = (rules) => Boolean(find(rules, ({ type }) => type === 'REQUIRED'));
export const getFieldError = (field) => {
  const { errors, touched } = field;

  if (!touched || !errors.length) {
    return null;
  }

  return get(errors, '[0].message') || true;
};

export const orderByOrderPosition = (items) => orderBy(items, 'orderPosition');

export const orderBySpecifiers = (items) => orderBy(items, 'specifiers');

const deprecatedMarketStatuses = ['DEACTIVATED', 'SETTLED', 'CANCELLED', 'HANDED_OVER'];

export const isOutcomeActive = (outcome) => {
  if (!outcome) return false;
  const { marketStatus } = outcome;

  return deprecatedMarketStatuses.indexOf(marketStatus) === -1;
};

export const getOrderedActiveOutcomes = (outcomes) => {
  const activeOutcomes = [];

  each(outcomes, (outcome) => {
    if (isOutcomeActive(outcome)) {
      activeOutcomes.push(outcome);
    }
  });

  return orderByOrderPosition(activeOutcomes);
};

export const formatDateWithTranslate = (date, t) => {
  const dateKey = moment(date).calendar(null, {
    sameDay: '[today]',
    nextDay: '[tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[yesterday]',
    lastWeek: '[last] dddd',
    sameElse: 'DD/MM/YYYY',
  });

  if (includes(dateKey, '/')) {
    return dateKey;
  }

  return t(snakeCase(dateKey));
};

export const getRegionIdByCategories = ({ categories, categoryId }) => get(find(categories, { id: categoryId }), 'regionId');

export const getGroupedMarkets = ({ markets }) => {
  let marketsWithGroups = [];
  const marketsWithoutGroups = [];

  each(markets, (market) => {
    const { groups } = market;
    if (groups.length > 0) {
      marketsWithGroups.push({
        group: groups[0],
        ...market,
      });
    } else {
      marketsWithoutGroups.push(market);
    }
  });

  marketsWithGroups = _(marketsWithGroups)
    .groupBy('group')
    .map((markets, group) => {
      const { orderPosition } = minBy(markets, 'orderPosition');
      const displayLayout = get(markets, '0.displayLayout');
      const sortedMarkets = sortBy(markets, 'orderPosition');

      return {
        name: group,
        group,
        orderPosition,
        displayLayout,
        markets: sortedMarkets,
      };
    })
    .value();

  return sortBy([...marketsWithoutGroups, ...marketsWithGroups], 'orderPosition');
};

export const separateMainMarkets = ({ markets }) => {
  const mainMarkets = [];
  const regularMarkets = [];
  each(markets, (market) => {
    const outcomes = toArray(market.outcomes);

    const mainOutcomes = [];
    const regularOutcomes = [];

    each(outcomes, (outcome) => {
      const { isMain } = outcome;
      if (isMain) {
        mainOutcomes.push(outcome);
      } else {
        regularOutcomes.push(outcome);
      }
    });

    if (mainOutcomes.length > 0) {
      mainMarkets.push({
        ...market,
        outcomes: mainOutcomes,
      });
    }
    if (regularOutcomes.length > 0) {
      regularMarkets.push({
        ...market,
        outcomes: regularOutcomes,
      });
    }
  });

  return {
    mainMarkets: sortBy(mainMarkets, 'orderPosition'),
    regularMarkets: sortBy(regularMarkets, 'orderPosition'),
  };
};

export const generateErrorFromCode = (code, message = code, violationInfo = {}) => ({
  type: code,
  message,
  violationInfo,
});
export const handleFormError = (error, setFormError = noop) => {
  const { message, code, status, violationInfo, errors } = error;

  // if (errors && errors.length) {
  // TODO: Implement multierrors format
  // }

  setFormError([generateErrorFromCode(code || status, message || code, violationInfo)]);
  throw error;
};
export const handleFormSubmit = (action, dataFormatter = identity) => async (formData, { setGlobalErrors, setIsLoading }) => {
  const formattedData = dataFormatter(formData);

  if (isNull(formattedData)) {
    return;
  }

  try {
    setIsLoading(true);
    const result = await action(formattedData);

    setIsLoading(false);

    return result;
  } catch (error) {
    setIsLoading(false);
    handleFormError(error, setGlobalErrors);
  }
};

export const getOutcomeId = (betItems, eventState) => {
  if (!eventState) return false;
  const eventInBetslip = find(betItems, (betItem) => {
    const eventId = get(betItem, 'event.id');
    return toNumber(eventId) === toNumber(eventState.id);
  });

  if (!eventInBetslip) {
    return null;
  }

  const outcome = getBetOutcome(eventInBetslip);

  return outcome && outcome.id;
};

export const formatHandicapLabel = (label) => (label > 0 ? `+${label}` : label);

export const getParsedFormErrorParameters = (params) => mapValues(params, (value, key) => {
  switch (key) {
    case 'limit': {
      return centsToDecimal(value);
    }
    default: {
      return value;
    }
  }
});

export const handleGlobalErrorsWithToast = ({ code, violationInfo, message }) => {
  ToastManager.error(() => (code ? (
    <Label message={code} params={getParsedFormErrorParameters({ ...violationInfo, message })} />
  ) : (
    message
  )));
};

export const getRenderedOutcomeStatus = (outcomeStatus) => {
  if (outcomeStatus === outcomeStatuses.NOT_DEFINED) {
    return 'TO_RUN';
  }
  return outcomeStatus;
};

export const setEmailVerificationRequested = (emailVerificationRequested) => {
  Bus.emit(Bus.events.profile.accountUpdate, { emailVerificationRequested });
};

export const getDayStart = (date) => date.startOf('day').unix() * 1000;

export const getDayEnd = (date) => date.endOf('day').unix() * 1000;
export const slugify = (str) => str;
export const getUrlSportName = (name) => slugify(name || DEFAULT_URL_SPORT_NAME);
export const getUrlCategoryName = (name) => slugify(name || DEFAULT_URL_CATEGORY_NAME);
export const getUrlTournamentName = (name) => slugify(name || DEFAULT_URL_TOURNAMENT_NAME);
export const getUrlEventName = (name) => slugify(name || DEFAULT_URL_EVENT_NAME);
export const getUrlRaceName = (name) => RaceTypesList.find((t) => t.id === name)?.typeName || RaceTypesList[0]?.typeName;

export const loadScript = (src) => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.onload = resolve;
  script.onerror = reject;
  script.src = src;
  document.head.append(script);
});

export function getConfig(name, defaultValue = null) {
  return get(window.SYS_CONFIG, name, defaultValue);
}

export class LocalConfig {
  static get(name, defaultValue = null) {
    return getConfig(name, defaultValue);
  }

  static is(name, right) {
    const left = getConfig(name);
    return left === right;
  }
}

export function flatConfig(config, acc = {}, prevkey = '') {
  Object.keys(config).forEach((key) => {
    acc[key] = isObject(config[key])
      ? flatConfig(config[[prevkey, key].join('.')], acc, key)
      : config[key];
  });

  return acc;
}

export const getExpandedMarkets = (event, marketTypeId) => chain(event)
  .get(`marketTypes[${marketTypeId}].markets`)
  .filter(isVisible)
  .orderBy([({ specifiers }) => Number(specifiers)])
  .value();

export const sortEventsOrEventGroupsByStartTime = ({ startTime }) => startTime || MAX_TIMESTAMP;

export const getEventMainMarket = (event) => {
  const marketType = chain(event).get('marketTypes').find({ mainPosition: 1 }).value();

  if (!marketType) {
    return null;
  }

  const market = chain(marketType).get('markets').find({ isMain: true }).value();

  if (!market) {
    return null;
  }

  return {
    ...market,
    marketTypeId: marketType.id,
    providerName: marketType.providerName,
  };
};

export const buildCdnUrl = (mediaPath, cdnKey = 'CDN_URL') => {
  const cdnPathName = LocalConfig.get(cdnKey);
  if (cdnPathName) {
    return new URL(mediaPath, cdnPathName);
  }
  return `/${mediaPath}`;
};

export const getIsAsianView = () => SiteConfigManager.getConfig('config.config.sportsbook.desktopViewType') === 'asian';

export const downloadFile = (fileName, fileData, type = 'text/plain') => {
  const blob = new Blob([fileData], {
    type,
  });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
    return true;
  }

  const csvData = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', csvData);
  link.setAttribute('target', '_blank');
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const arrayToCsv = (header, array, separator = ',') => {
  const wrapValue = (value) => `"${value}"`;

  let headerStr = '';
  let body = '';
  if (header && header.length) {
    headerStr = header.map(wrapValue).join(separator);
  }

  if (array && array.length) {
    body = array.map((item) => Object.values(item).map(wrapValue).join(separator)).join('\n');
  }
  return headerStr ? `${headerStr}\n${body}` : `${body}`;
};

export const selectNextToJump = ({
  races = [],
  limit = 3,
  types = [RaceTypesMap.HORSE.id, RaceTypesMap.DOG.id, RaceTypesMap.TROT.id],
}) => {
  const currentTime = new Date();

  return chain(races)
    .filter(
      (r) => r.status === RACE_STATUS.OPEN
        && types.includes(r.raceType.id)
        && DateService.getTimeDifference(currentTime, r.startTime) < 30 * 60 * 1000, // exclude races started 30 min ago
      /* && r.hasFixedOdds */
    )
    .orderBy(['startTime'])
    .value()
    .slice(0, limit || 3);
};

export function ordinalSuffixOf(i) {
  if (i) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }
  return '';
}

export const getOutcomeSelections = (outcome) => {
  // parse outcomes and runners
  const arr = outcome?.selection?.split('/');
  const selections = [];
  arr?.forEach((runnersStr, i) => {
    if (runnersStr) {
      const runners = runnersStr.split(',');
      runners.forEach((runner) => {
        const obj = { runner, place: i + 1 };
        selections.push(obj);
      });
    }
  });
  return selections;
};

export const isBoxedSelections = (outcome) => {
  const sel = outcome?.selection;
  if (isString(sel)) {
    return sel.toLowerCase().startsWith('boxed');
  }
  return false;
};

export const formatOdds = (odds, decimal = 2, minDecimal = 0) => {
  const formatter = new Intl.NumberFormat('en-AU', {
    maximumFractionDigits: decimal,
    minimumFractionDigits: minDecimal,
  });
  return odds ? formatter.format(odds) : '';
};

export const groupEventsByDate = (events, getIsEventFavorite) => chain(events)
  .groupBy((event) => {
    const [id, startTime] = at(event, ['id', 'startTime']);
    const isEventInFavorites = getIsEventFavorite ? getIsEventFavorite({ eventId: id }) : false;

    if (isEventInFavorites) {
      return MIN_TIMESTAMP;
    }

    if (!startTime) {
      return MAX_TIMESTAMP;
    }

    return moment(startTime).startOf('day').valueOf();
  })
  .map((events, startTime) => ({
    events,
    startTime,
  }))
  .sortBy(sortEventsOrEventGroupsByStartTime)
// .map(({ events }) => sortBy(events, sortEventsOrEventGroupsByStartTime))
  .map(({ events }) => sortBy(events, ['startTime', 'correlationId']))
  .value();

export const extractSportMarketTypes = (sports, sportId) => {
  const mainMarketTypes = chain(sports)
    .find(({ id }) => id === sportId)
    .get('mainMarketTypes');
  return getSportMainMarketTypesByPeriod(mainMarketTypes);
};

export const EVENT_LIST_TEMPLATE = {
  CLASSIC: 'classic',
  DATE: 'date',
  MULTI_MARKETS: 'multi-markets',
  OUTRIGHT: 'outright',
};

export const getEventListTemplateBySport = (sportName) => {
  switch (sportName) {
    case 'Soccer':
    case 'Ice Hockey':
    case 'Boxing':
    case 'Mixed Martial Arts':
    case 'Snooker':
    case 'Tennis':
    case 'Golf':
    case 'Cricket':
      return EVENT_LIST_TEMPLATE.CLASSIC;
    case 'Politics':
      return EVENT_LIST_TEMPLATE.OUTRIGHT;
    default:
      return EVENT_LIST_TEMPLATE.MULTI_MARKETS;
  }
};

export const overUnderMapping = {
  O: 'O',
  'O {specifier}': 'O',
  U: 'U',
  'U {specifier}': 'U',
  // Draw: 'Draw',
};

export const multiBetLabels = { 2: 'Doubles', 3: 'Trebles' };

export const transactionPropertiesParser = (props) => props?.BET?.outcomes?.map((o) => o.outcomeInfo) || props?.OUT_COME_INFO || [];

export const getEventDate = (startTime, t) => {
  let title = '';
  if (moment(startTime).isSame(moment(), 'day')) {
    title = t('today');
  } else if (moment().add(1, 'day').isSame(startTime, 'day')) {
    title = capitalize(t('tomorrow'));
  } else {
    title = moment(startTime).format('ddd D MMM');
  }
  return title;
};

export const getSpecialDate = (dateKey) => {
  const dtToday = DateUtils.todayDateKey();
  const dtTomorrow = DateUtils.futureDateKey(1);
  if (!dateKey) {
    return specialDates.Today;
  }
  if (dateKey === dtToday) {
    return specialDates.Today;
  }
  if (dateKey === dtTomorrow) {
    return specialDates.Tomorrow;
  }
  return dateKey;
};

export const checkForExotic = (bet) => {
  const betMarketType = bet?.outcomes && bet.outcomes[0]?.outcomeInfo.marketTypeId;
  return isExoticMarket(betMarketType);
};

export const checkForMultilegs = (bet) => {
  const betMarketType = bet?.outcomes && bet.outcomes[0]?.outcomeInfo.marketTypeId;
  return isMultilegsMarket(betMarketType);
};

export const getLegsCountFromSelection = (selection) => (selection?.split(/\/|,/) || []).filter((outcome) => outcome).length;

export const getRunnerByNum = (runners, num) => runners?.find((runner) => `${runner.number}` === num);

export const getFavOddRunner = (runners, outcomesByRunnerId) => {
  let favOddRunner = null;
  let favOddValue = Infinity;

  runners?.forEach((r) => {
    const odds = outcomesByRunnerId?.[r?.runnerId]?.F_WIN?.odds;
    if (!r.scratched && odds < favOddValue) {
      favOddRunner = r.runnerId;
      favOddValue = odds;
    }
  });
  return favOddRunner;
};

export const getLegsFromSgmOutcome = (outcome) => outcome.outcomeInfo.sameGameMultiLegs.map((leg) => ({
  name: leg.outcomeName,
  marketTypeName: leg.localizedMarketName,
  outcomeStatus: leg.outcomeStatus,
}));

export const extractRaceIdsFromBets = (bets = []) => bets.reduce((list, bet) => {
  const eventType = bet.betEventType || bet.betSportService || null;
  if (eventType !== BET_EVENT_TYPE.RACING) {
    return list;
  }
  if (bet?.outcomes?.length) {
    return [
      ...list,
      ...(bet.outcomes || [])
        .filter((bo) => bo.outcomeInfo?.marketTypeId === RaceLogicMarkets.SAME_RACE_MULTI)
        .map((bo) => bo.outcomeInfo.eventId),
    ];
  }
  return [...list, bet.eventId];
}, []);

export const prepareBetSlipRunners = (raceIds = []) => {
  // const raceIds = extractRaceIdsFromBets(bets);
  if (!raceIds || !raceIds.length) {
    return;
  }
  Bus.emit(Bus.events.racing.loadEventRunners, {
    raceIds: uniq(raceIds),
  });
};

export const getSortedRunners = (
  runners,
  outcomesByRunnerId = {},
  sortByField = 'runner',
  isAsc = true,
) => {
  const sortedRunners = [...runners];
  if (!sortByField || sortByField === 'runner') {
    return sortedRunners.sort((a, b) => (isAsc ? a.number - b.number : b.number - a.number));
  }

  if (SRM_MARKETS_LIST.includes(sortByField)) {
    return sortedRunners.sort((a, b) => (isAsc ? a[sortByField] - b[sortByField] : b[sortByField] - a[sortByField]));
  }

  return sortedRunners.sort((a, b) => (isAsc
    ? outcomesByRunnerId[a.runnerId]?.[sortByField]?.odds
        - outcomesByRunnerId[b.runnerId]?.[sortByField]?.odds
    : outcomesByRunnerId[b.runnerId]?.[sortByField]?.odds
        - outcomesByRunnerId[a.runnerId]?.[sortByField]?.odds));
};

export const getRaceNumbersFromOutcomeInfo = ({ marketName }) => marketName.substring(marketName.indexOf('(') + 1, marketName.indexOf(')')).split('-');

export const oddsAnimation = (oddsButton, isGlobal, innerText = '') => {
  const oddsButtonRect = oddsButton.getBoundingClientRect();
  const right = window.innerWidth - oddsButtonRect.right;
  let flyingBlock = document.createElement('div');

  if (isGlobal) {
    // append to body
    flyingBlock.innerText = innerText;
    document.body.appendChild(flyingBlock);
  } else {
    flyingBlock = oddsButton.cloneNode(true);
    flyingBlock.style.width = `${oddsButtonRect.width}px`;
    oddsButton.after(flyingBlock);
  }
  flyingBlock.className += ' odds-animation';
  flyingBlock.style.right = `${right}px`;
  flyingBlock.style.top = `${oddsButtonRect.top}px`;

  const motionAnimation = flyingBlock.animate(
    [
      {
        opacity: 0.8,
      },
      {
        transform: `translate3d(${right - 10}px,${40 - oddsButtonRect.top}px, 0) rotateZ(0.33turn)`,
        opacity: 0.5,
        easing: 'ease-in',
      },
    ],
    { duration: 300 },
  );

  motionAnimation.onfinish = () => {
    flyingBlock.remove();
  };
};

export const yieldToMain = (cb) => {
  setTimeout(cb, 0);
};

export const generateSportLink = (entity) => {
  const sportPath = SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`);
  const keys = ['sportUrlName', 'categoryUrlName', 'tournamentUrlName', 'urlName'];
  const parts = [];
  keys.forEach((k) => {
    if (!isEmpty(entity[k])) parts.push(entity[k]);
  });
  return `${sportPath}/${parts.join('/')}`;
};

export const generateRaceLink = ({
  raceDate,
  raceType,
  raceCountry,
  raceVenue,
  raceNumber,
  marketTab,
  marketType,
}) => {
  const racingPath = SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.RACING}`);

  return generatePath(
    `${racingPath}/:raceDate?/:raceType?/:raceCountry?/:raceVenue?/:raceNumber?/:marketTab?/:marketType?`,
    {
      raceDate,
      raceType,
      raceCountry,
      raceVenue,
      raceNumber,
      marketTab,
      marketType,
    },
  );
};

export const getRaceInfoFromOutcomeInfo = (outcomeInfo) => {
  // this is a trick to find race from bet history outcomeInfo
  const [raceVenue, raceCountry] = (outcomeInfo.tournamentName || '').split(', ');
  const raceNumber = outcomeInfo.eventName?.match(/\d+$/) || [];
  const raceType = RaceTypesMap[outcomeInfo.sportId];
  return {
    raceDate: outcomeInfo.categoryId,
    raceType: raceType?.typeName || '',
    raceCountry,
    raceVenue,
    raceNumber: raceNumber[0],
  };
};

export const getRaceCompleteText = (race) => race?.result?.replace(/,*\s*$/, '') || race?.status || '';

export const getCurrencySymbol = (currency = 'AUD') => currencySymbols[currency] || '';

export const isTBD = ({ outcomes }) => {
  const outcomesArr = outcomes?.every((outcome) => !isEmpty(outcome?.outcomeInfo))
    ? outcomes?.map((o) => o.outcomeInfo) || []
    : outcomes || [];

  return outcomesArr.some(
    (outcome) => outcome?.outcomeStatus === outcomeStatuses.NOT_DEFINED
      && SPECIAL_MARKETS[outcome?.marketTypeId],
  );
};

export const scrollToTop = ({ isMobileDevice }) => {
  const scrollableElement = isMobileDevice
    ? document.querySelector('html')
    : document.querySelector('.main__page');
  // scroll page to top if URL changed.
  scrollableElement.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const isMarketOddsClickable = (displayLayout) => [
  DISPLAY_LAYOUTS.HANDICAP,
  DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE,
  DISPLAY_LAYOUTS.THREE_COLUMNS,
  DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS,
  DISPLAY_LAYOUTS.SPEC_DESC_TWO_COLUMNS_SORTED,
  DISPLAY_LAYOUTS.ODDS_SORTING_GROUPED_BY_MARKET,
].includes(displayLayout);

export const isDef = (v) => v !== undefined && v !== null;

export const isDefaultOddsAreBoostedAndMultiOddsAreNotBoosted = () => SiteConfigManager.getConfig(
  'config.config.sportsbook.defaultOddsAreBoostedAndMultiOddsAreNotBoosted',
  false,
);

/**
 * When regular "odds" are considered "boosted" then "oddsForMulti" play a role of "not boosted" odds
 */
export const isOutcomeDefaultOddsAreBoosted = (outcome = {}) => {
  const { odds, oddsForMulti } = outcome;
  return (
    isDefaultOddsAreBoostedAndMultiOddsAreNotBoosted() === true
    && isDef(oddsForMulti)
    && odds > oddsForMulti
  );
};
