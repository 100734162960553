import { constants } from '@apollo/core';
import React, { useRef } from 'react';
import cx from 'classnames';
import { isEmpty, map } from 'lodash';
import { MARKET_STATUS } from '../../../core/constants';
import RaceOutcome from '../RaceOutcome/RaceOutcome';
import RaceRunner from './RaceRunner';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { RacingMarketTypeName } from '../../../shared/components/Racing/RacingMarketTypeName';

const ExtraMarketContainer = ({
  extraMarkets,
  runnersByNumber,
  setIsExtraMarketExpand,
  isExtraMarketExpanded,
  handleExpand,
  activeRace,
  activeMeet,
  expandedMarkets,
}) => {
  const t = useTranslate();
  const extraMarketsContentRef = useRef();

  const scrollIntoView = () => {
    window.setTimeout(
      () => extraMarketsContentRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      }),
      100,
    );
  };

  return (
    <div className={`extra_market_container ${!isExtraMarketExpanded ? 'collapsed' : ''}`}>
      {!isEmpty(extraMarkets) && (
        <div>
          <div
            className='extra_market_container__header'
            onClick={() => {
              setIsExtraMarketExpand(!isExtraMarketExpanded);
              if (!isExtraMarketExpanded) {
                scrollIntoView();
              }
            }}
          >
            <span className={`icon AIcon-angle-${!isExtraMarketExpanded ? 'down' : 'up'}`} />
            {t('EXTRA MARKETS')}
          </div>

          {isExtraMarketExpanded && (
            <div className='extra_market_content' ref={extraMarketsContentRef}>
              {map(extraMarkets, (market, index) => {
                if (
                  [MARKET_STATUS.CANCELLED, MARKET_STATUS.HIDDEN, MARKET_STATUS.OFF].includes(
                    market.status,
                  )
                ) {
                  return null;
                }

                return (
                  <div
                    className={cx(
                      'extra_market_content_container',
                      { suspended: market.status === constants.STATUS.SUSPENDED },
                      { expanded: expandedMarkets.includes(index) },
                    )}
                    key={market.marketId}
                  >
                    <div className='market_name' onClick={() => handleExpand(index)}>
                      <span
                        className={`icon AIcon-angle-${expandedMarkets.includes(index) ? 'up' : 'down'}`}
                      />
                      <RacingMarketTypeName marketType={market.type} useFullName />
                    </div>
                    {expandedMarkets.includes(index) && (
                      <div className='market_runners'>
                        <div className='tournament--classic racelist'>
                          <div className='event-group event-group--races'>
                            {isEmpty(market.outcomes) === false
                              && map(market.outcomes, (outcome) => (
                                <div
                                  key={outcome.outcomeId}
                                  className={`event event--classic ${
                                    (runnersByNumber[outcome.specifier] || {}).scratched
                                      ? 'event--scratched'
                                      : ''
                                  }`}
                                >
                                  <div className='event__main-period'>
                                    {!outcome.name || market.type === 'HEAD_TO_HEAD' ? (
                                      <RaceRunner
                                        runner={runnersByNumber[outcome.specifier] || {}}
                                        activeRace={activeRace}
                                        hideJockey
                                        showRunnerImage
                                        marketOutcome={
                                          market.type === 'HEAD_TO_HEAD' ? outcome : null
                                        }
                                      />
                                    ) : (
                                      <div className='event__info'>
                                        <div className='outcome_name'>{outcome.name}</div>
                                      </div>
                                    )}
                                    <div className='event__market-row'>
                                      <div className='event__market-row-market'>
                                        <RaceOutcome
                                          key={outcome.outcomeId}
                                          meet={activeMeet}
                                          race={activeRace}
                                          runner={runnersByNumber[outcome.specifier] || {}}
                                          market={market}
                                          outcome={outcome}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default ExtraMarketContainer;
