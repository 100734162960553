import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { constants, getAvailablePages, SiteConfigManager } from '@apollo/core';
import { availableClientProfileRoutes, COMPONENT_TYPES } from '@apollo/routing';
import { some } from 'lodash';
import useTranslate from '../../I18n/Interpreter';
import Label from '../../I18n/Label';
import { isDateFuture } from '../../../../features/ClientProfile/Bonuses/BonusesTable/bonusesUtils';
import { isPromotionsActive } from '../../../../core/utils';
import { BonusSelector } from '../../../../state/Bonus/Bonus';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

const BONUS_TABS = [
  // constants.BONUS_TYPE.FREEBET_DEPOSIT,
  constants.BONUS_TYPE.ODDS_BOOST,
  constants.BONUS_TYPE.FREEBET,
  constants.BONUS_TYPE.BET_RETURN,
];

const HeaderInfoAuthorizedBonuses = ({ onClick }) => {
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);

  const promotionsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.PROMOTIONS);

  const t = useTranslate();

  const bonuses = BonusSelector.selectAllBonuses();
  const tabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs),
    [clientProfileTabs],
  );
  const promotionsActive = useMemo(() => isPromotionsActive(), [promotionsRoute]);

  const bonusLink = useMemo(() => tabs?.find((t) => t.type === 'bonuses')?.link || '', [tabs]);

  const hasActivePromoOdds = some(
    bonuses.filter((b) => isDateFuture(b.props?.expiry)),
    ['bonusType', constants.BONUS_TYPE.PROMO_ODDS],
  );

  const bonusTabs = useMemo(() => {
    if (hasActivePromoOdds && !BONUS_TABS.includes(constants.BONUS_TYPE.PROMO_ODDS)) {
      return [constants.BONUS_TYPE.PROMO_ODDS, ...BONUS_TABS];
    }

    return [...BONUS_TABS];
  }, [hasActivePromoOdds]);

  return bonuses.length > 0 ? (
    <>
      <div className='account_dropdown__bonuses'>
        {bonusTabs?.map((tab) => {
          const depositLink = tabs.find((t) => t.name === 'deposit_withdraw')?.link;
          const link = tab === constants.BONUS_TYPE.FREEBET_DEPOSIT
            ? `${clientProfilePath}/${depositLink}`
            : `${clientProfilePath}/${bonusLink}/${tab.toLowerCase()}`;
          return (
            <NavLink key={tab} className='bet-bonus--link' to={link} onClick={onClick}>
              <div className='bet-bonus--icon'>
                <div className='bet-bonus--icon--wrapper'>
                  <i className={`icons icon--${tab.toLowerCase()}-black`} title={t(tab)} />
                </div>
                <span className='bet-bonus--counter'>
                  {
                    bonuses.filter(
                      (b) => b.bonusType === tab
                        && (isDateFuture(b.props?.expiry)
                          || b.bonusType === constants.BONUS_TYPE.FREEBET_DEPOSIT),
                    ).length
                  }
                </span>
              </div>
              <Label message={tab} />
            </NavLink>
          );
        })}
      </div>
      <div className='account_dropdown__bonuses account_dropdown__bonuses--all'>
        <div className='bet-bonus--animation' />
        <NavLink
          className='bet-bonus--link--all'
          to={promotionsActive ? promotionsRoute : `${clientProfilePath}/${bonusLink}`}
          onClick={onClick}
        >
          <div className='bet-bonus--content'>
            {promotionsActive ? (
              <i className='icons icon--bet-bonus-promotions' />
            ) : (
              <i className='icons icon--bet-bonus-all' />
            )}
            <Label message={promotionsActive ? 'Promotions' : 'Bonuses'} />
          </div>
        </NavLink>
      </div>
    </>
  ) : null;
};

export default HeaderInfoAuthorizedBonuses;
