import { RaceTypesMap } from '@apollo/core/src/constants';
import React from 'react';
import { DateUtils, SiteConfigManager, useApplicationState } from '@apollo/core';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import { get } from 'lodash';
import Theme from 'themeSource/custom';
import Loader from '../../../shared/components/Loader';
import NextToJumpType from './Racing/NextToJumpType';
import NextToJumpAll from './Racing/NextToJumpAll';
import RaceTypeButtonList from './Racing/RaceTypeButtonList';
import { cmsWidgetPageBlock } from '../../../core/constants';
import WidgetLoader from '../../../shared/components/Cms/Widgets/WidgetLoader';
import Label from '../../../shared/components/I18n/Label';

const RacingTab = () => {
  const { layout } = useApplicationState();
  const [racingState] = useRacingState();
  const { initialized, loading, dates } = racingState;

  const widgets = get(SiteConfigManager.widgets, cmsWidgetPageBlock.HomePageRacing) || [];

  const raceDate = dates?.[0]?.date || DateUtils.todayDateKey();

  if (!initialized && loading) {
    return <Loader />;
  }

  return (
    <div className='home-tab home-tab--racing'>
      <RaceTypeButtonList raceDate={raceDate} />

      <h3 className='home-next-jump__title'>
        <Label message='next_to_jump' />
      </h3>

      <div className='home-next-jump'>
        {layout.mobileDevice === true ? <NextToJumpAll /> : null}
        <NextToJumpType raceDate={raceDate} raceType={RaceTypesMap.HORSE.id} />
        <NextToJumpType raceDate={raceDate} raceType={RaceTypesMap.DOG.id} />
        <NextToJumpType raceDate={raceDate} raceType={RaceTypesMap.TROT.id} />
      </div>
      {widgets.map((w) => (
        <WidgetLoader key={w.id} widget={w} />
      ))}
    </div>
  );
};

export default RacingTab;
