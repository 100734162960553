import React from 'react';
import Odds from './Odds';

const PromoOdds = ({ className = '', promoOdds, odds, decimal = 3, isIcon = false }) => (
  <span className={`promo-odds ${className || ''}`}>
    <span className='odds__promo-odds_new'>
      <Odds value={promoOdds} decimal={decimal} minDecimal={2} />
    </span>
    <span className='odds__promo-odds_old'>
      <Odds value={odds} decimal={decimal} minDecimal={2} />
    </span>

    {isIcon ? <i className='bonus-odd__icon' /> : null}
  </span>
);

export default PromoOdds;
