import React, { useCallback, useMemo, useState } from 'react';
import { useApplicationState } from '@apollo/core';
import moment from 'moment';
import cx from 'classnames';
import MediaQuery from 'react-responsive';
import { useRacingState } from '@apollo/core/src/state/racing/racing';
import { isEmpty, map } from 'lodash';
import Label from '../../../shared/components/I18n/Label';
import RaceResultsTable from './raceResults/RaceResultsTable';
import RaceDividendTable from './raceResults/RaceDividendTable';
import RaceExoticDividendTable from './raceResults/RaceExoticDividendTable';
import RaceDeductionsTable from './raceResults/RaceDeductionsTable';
import RaceOutcome from '../RaceOutcome/RaceOutcome';
import RacePageTableHeader from './RacePageTableHeader/RacePageTableHeader';
import { RACE_STATUS } from '../../../core/constants';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import ExtraMarketContainer from './ExtraMarketContainer';
import {
  formatOdds,
  getFavOddRunner,
  getSortedRunners,
  ordinalSuffixOf,
} from '../../../core/utils';
import RaceMultilegsTable from './raceResults/RaceMultilegsTable';
import RaceFlucs from '../raceFlucs/RaceFlucs';
import RaceRunnerRow from './RaceRunnerRow';
import { RacingMarketTypeName } from '../../../shared/components/Racing/RacingMarketTypeName';

const RacingResultsTabComponentMap = {
  1: RaceResultsTable,
  2: RaceDividendTable,
  3: RaceExoticDividendTable,
  4: RaceMultilegsTable,
  5: RaceDeductionsTable,
};

const marketDeductKeyMap = {
  F_WIN: 'deductionOnWin',
  F_PLC: 'deductionOnPlace',
  VIC_T_W: 'none-1',
  VIC_T_P: 'none-2',
  BT_SP: 'none-3',
  MID_T_W: 'none-4',
  MID_T_P: 'none-5',
  FAV_OUT: 'deductionOnFavOut',
  CONCESSION: 'deductionOnConcession',
};

const WinPlaceRacePage = ({ activeMeet, runners, extraMarkets, raceStatic, displaySettings }) => {
  const [racingResultsTab, setRacingResultsTab] = useState(1);
  const [isExpanded, setExpanded] = useState(false);
  const [expandedMarkets, setExpandedMarkets] = useState([0]);
  const [isExtraMarketExpanded, setIsExtraMarketExpand] = useState(true);
  const [activeColumnsMobile, setActiveColumnsMobile] = useState('FIXED_WP');
  const { layout } = useApplicationState();
  const [racingState] = useRacingState();
  const { activeRace, marketsByType, outcomesByRunnerId, marketTypes } = racingState;
  const t = useTranslate();

  const runnersByNumber = useMemo(
    () => runners.reduce((acc, r) => ({ ...acc, [r.number]: r }), {}),
    [runners],
  );

  const ResultComponent = RacingResultsTabComponentMap?.[racingResultsTab];

  const { availableRaceMarkets, availableRaceMarketsMobile } = React.useMemo(() => {
    const raceMarketsList = (activeRace?.markets || []).filter(
      (m) => isEmpty(m.outcomes) === false && m.typeUiPosition === 'WP_TAB',
    );

    return raceMarketsList.reduce(
      (list, mt) => {
        if (!mt) {
          return list;
        }
        const deductionType = marketDeductKeyMap[mt.type] ?? null;
        const market = {
          marketType: mt.type,
          deductionType,
        };

        const typeGroup = mt.typeGroup === 'FIXED_EXTRA' ? mt.type : mt.typeGroup;

        return {
          availableRaceMarkets: [...list.availableRaceMarkets, market],
          availableRaceMarketsMobile: {
            ...list.availableRaceMarketsMobile,
            [typeGroup]: [...(list.availableRaceMarketsMobile[typeGroup] || []), market],
          },
        };
      },
      { availableRaceMarkets: [], availableRaceMarketsMobile: {} },
    );
  }, [activeRace]);

  const handleExpand = useCallback((key) => {
    setExpandedMarkets((prevExpandedMarkets) => {
      const updatedExpandedMarkets = prevExpandedMarkets.includes(key)
        ? prevExpandedMarkets.filter((market) => market !== key)
        : [...prevExpandedMarkets, key];

      return updatedExpandedMarkets;
    });
  }, []);

  const shrinkDropdown = useCallback(() => {
    setExpanded(false);
  }, [setExpanded]);

  const renderDeductions = (r) => {
    let deductions = 0;
    const markets = layout.mobileDevice
      ? availableRaceMarketsMobile[activeColumnsMobile]
      : availableRaceMarkets;

    const deductionsHtml = map(markets, (mt) => {
      const deductVal = formatOdds(r?.runnerOdds?.[mt?.deductionType], 2);
      if (deductVal > 0) {
        deductions += deductVal > 0;
      }
      return (
        <div
          key={`${r.runnerId}-${mt?.deductionType}-${mt?.marketType}`}
          className='event__market-row-market'
        >
          {deductVal > 0 ? `${deductVal}c` : ''}
        </div>
      );
    });
    return (
      <div className='deductions-wrap'>
        <div className='deduction-values'>{deductionsHtml}</div>
        <div>{t(`${deductions > 0 ? '' : 'No'} deductions applied`)}</div>
      </div>
    );
  };

  const favOddRunner = getFavOddRunner(runners, outcomesByRunnerId);

  // console.log('LURWA', activeRace.endTime, activeRace);

  return (
    <div>
      {activeRace?.uiResults ? (
        <div className='race__results'>
          <div className='race__results-header'>
            <h3>
              <Label message='final-race-results' />
            </h3>
            <div className='race__results-time'>
              {moment(activeRace.startTime).format('DD MMM hh:mma')}
            </div>
          </div>
          <div className='race-results_tab'>
            <div
              className={cx('race-results_tab__item', {
                active: racingResultsTab === 1,
              })}
              onClick={() => setRacingResultsTab(1)}
            >
              <Label message='results' />
            </div>
            <div
              className={cx('race-results_tab__item', {
                active: racingResultsTab === 2,
              })}
              onClick={() => setRacingResultsTab(2)}
            >
              <Label message='dividends' />
            </div>
            {activeRace?.uiResults?.exoticsDividends?.length ? (
              <div
                className={cx('race-results_tab__item', {
                  active: racingResultsTab === 3,
                })}
                onClick={() => setRacingResultsTab(3)}
              >
                <Label message='exotics' />
              </div>
            ) : null}
            {activeRace?.uiResults?.multilegsDividends?.length ? (
              <div
                className={cx('race-results_tab__item', { active: racingResultsTab === 4 })}
                onClick={() => setRacingResultsTab(4)}
              >
                <Label message='multiples' />
              </div>
            ) : null}
            {activeRace?.runners.filter((runner) => runner.scratched)?.length ? (
              <div
                className={cx('race-results_tab__item', {
                  active: racingResultsTab === 5,
                })}
                onClick={() => setRacingResultsTab(5)}
              >
                <Label message='deductions' />
              </div>
            ) : null}
          </div>
          <div className='race-results_tab_content'>
            {ResultComponent ? (
              <ResultComponent
                activeMeet={activeMeet}
                availableRaceMarkets={availableRaceMarkets}
                activeRace={activeRace}
                marketDeductKeyMap={marketDeductKeyMap}
              />
            ) : null}
          </div>
        </div>
      ) : null}

      <MediaQuery minWidth={1801}>
        <div className='lg-width'>
          <div className='runner__list'>
            <RunnersList
              {...{
                favOddRunner,
                activeRace,
                racingResultsTab,
                setRacingResultsTab,
                setExpanded,
                activeColumnsMobile,
                shrinkDropdown,
                availableRaceMarketsMobile,
                availableRaceMarkets,
                runners,
                renderDeductions,
                isExpanded,
                ResultComponent,
                activeMeet,
                marketsByType,
                marketTypes,
                outcomesByRunnerId,
                raceStatic,
                displaySettings,
              }}
            />
          </div>
          <div className='extra_market__list'>
            <ExtraMarketContainer
              {...{
                extraMarkets,
                runnersByNumber,
                setIsExtraMarketExpand,
                isExtraMarketExpanded,
                handleExpand,
                activeRace,
                activeMeet,
                expandedMarkets,
              }}
            />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1800}>
        <RunnersList
          {...{
            favOddRunner,
            activeRace,
            racingResultsTab,
            setRacingResultsTab,
            setExpanded,
            activeColumnsMobile,
            shrinkDropdown,
            availableRaceMarketsMobile,
            availableRaceMarkets,
            runners,
            renderDeductions,
            isExpanded,
            ResultComponent,
            activeMeet,
            marketsByType,
            marketTypes,
            outcomesByRunnerId,
            setActiveColumnsMobile,
            raceStatic,
            displaySettings,
          }}
        />
        <ExtraMarketContainer
          {...{
            extraMarkets,
            runnersByNumber,
            setIsExtraMarketExpand,
            isExtraMarketExpanded,
            handleExpand,
            activeRace,
            activeMeet,
            expandedMarkets,
          }}
        />
      </MediaQuery>
    </div>
  );
};

const RunnersList = ({
  activeRace,
  activeColumnsMobile,
  availableRaceMarketsMobile,
  availableRaceMarkets,
  runners,
  renderDeductions,
  activeMeet,
  marketsByType,
  marketTypes,
  outcomesByRunnerId,
  favOddRunner,
  setActiveColumnsMobile,
  raceStatic,
  displaySettings = {},
}) => {
  const { layout } = useApplicationState();
  const t = useTranslate();

  const { isFlucs, isSpeedMap, isForm } = displaySettings;

  const [sortByField, setSortByField] = useState({
    field: 'runner',
    asc: true,
  });

  const sortedRunners = useMemo(() => {
    const sortedRunners = getSortedRunners(
      runners,
      outcomesByRunnerId,
      sortByField.field,
      sortByField.asc,
    );
    return sortedRunners.sort((a, b) => a.scratched - b.scratched);
  }, [runners, sortByField, outcomesByRunnerId]);

  return (
    <div>
      <div className='race_list_wrapper'>
        {layout.mobileDevice === true ? (
          <div className='mobile-column-chooser'>
            <select
              className='race-page-columns-mobile-container'
              onChange={({ target: { value } }) => setActiveColumnsMobile(value)}
            >
              {Object.keys(availableRaceMarketsMobile).map((item) => {
                const marketTypeInfo = marketTypes.find((market) => market.type === item);

                return (
                  <option
                    key={item}
                    value={item}
                    /* selected={activeColumnsMobile === item} */
                    className={`${activeColumnsMobile === item ? 'active options' : 'options'}`}
                  >
                    {marketTypeInfo?.name || t(item)}
                  </option>
                );
              })}
            </select>
          </div>
        ) : null}
        <div
          className={`tournament tournament--classic racelist racelist-${availableRaceMarkets.length}`}
        >
          <RacePageTableHeader
            availableRaceMarkets={availableRaceMarkets}
            activeColumnsMobile={activeColumnsMobile}
            availableRaceMarketsMobile={availableRaceMarketsMobile}
            sortByField={sortByField}
            setSortByField={setSortByField}
            activeRace={activeRace}
          />
          <div className='event-group event-group--races'>
            {isEmpty(sortedRunners) === false
              && map(sortedRunners, (r) => (
                <RaceRunnerRow
                  key={r.runnerId}
                  runner={r}
                  runnerStatic={raceStatic?.runners?.find(
                    (runner) => runner.runnerId === r.runnerId,
                  )}
                  activeRace={activeRace}
                  isFlucs={isFlucs}
                  isForm={isForm}
                  isSpeedMap={isSpeedMap}
                >
                  <RaceFlucs activeRace={activeRace} runner={r} />
                  {layout.mobileDevice === false ? (
                    <div className='event__market-row desktop__values'>
                      {!r.scratched && isEmpty(availableRaceMarkets) === false
                        ? map(availableRaceMarkets, (mt, index) => {
                          const market = marketsByType?.[mt?.marketType];
                          const outcome = outcomesByRunnerId?.[r.runnerId]?.[mt?.marketType];
                          return (
                            <div
                              key={`${r.runnerId}-${mt?.marketType}`}
                              className='event__market-row-market'
                            >
                              {!r.scratched && outcome != null ? (
                                <RaceOutcome
                                  key={outcome.outcomeId}
                                  meet={activeMeet}
                                  race={activeRace}
                                  runner={r}
                                  market={market}
                                  outcome={outcome}
                                  isFavourite={favOddRunner === r.runnerId}
                                />
                              ) : null}
                              {activeRace.status === RACE_STATUS.OPEN
                                && favOddRunner === r.runnerId
                                && index === 0 ? (
                                  <Label message='fav' className='favorite' />
                                ) : null}
                            </div>
                          );
                        })
                        : r.scratched && isEmpty(availableRaceMarkets) === false
                          ? renderDeductions(r)
                          : null}
                    </div>
                  ) : null}
                  {layout.mobileDevice === true ? (
                    <div className='event__market-row mobile__values'>
                      {!r.scratched && isEmpty(activeColumnsMobile) === false
                        ? map(availableRaceMarketsMobile[activeColumnsMobile], (mt, index) => {
                          const market = marketsByType?.[mt?.marketType];
                          const outcome = outcomesByRunnerId?.[r.runnerId]?.[mt?.marketType];
                          return (
                            <div
                              key={`${r.runnerId}-${mt?.marketType}`}
                              className='event__market-row-market'
                            >
                              {!r.scratched && outcome != null ? (
                                <RaceOutcome
                                  key={outcome.outcomeId}
                                  meet={activeMeet}
                                  race={activeRace}
                                  runner={r}
                                  market={market}
                                  outcome={outcome}
                                />
                              ) : null}
                              {activeRace.status === RACE_STATUS.OPEN
                                && favOddRunner === r.runnerId
                                && index === 0 ? (
                                  <Label message='fav' className='favorite' />
                                ) : null}
                            </div>
                          );
                        })
                        : r.scratched && isEmpty(availableRaceMarkets) === false
                          ? renderDeductions(r)
                          : null}
                    </div>
                  ) : null}
                </RaceRunnerRow>
              ))}
          </div>
        </div>
      </div>
      {activeRace ? (
        <div className='place-information'>
          <div>
            {activeRace.numPlaces
              ? `${t('place')} pays ${Array(activeRace.numPlaces)
                .fill()
                .map((_, i) => i + 1)
                .join()}`
              : 'No Place'}
          </div>
          {activeRace.concessionMbPos ? (
            <div>
              <RacingMarketTypeName marketType='CONCESSION' useFullName />
              {`: ${t('money back for')} ${Array(activeRace.concessionMbPos)
                .fill()
                .map((_, i) => (i + 1 > 1 ? ordinalSuffixOf(i + 1) : null))
                .filter((i) => i)
                .join()}`}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default WinPlaceRacePage;
