import React from 'react';
import { useApplicationState } from '@apollo/core';
import cx from 'classnames';
import Theme from 'themeSource/custom';
import HeaderInfoUnauthorized from './HeaderInfoUnauthorized';
import HeaderInfoAuthorized from './HeaderInfoAuthorized';
import BetSlipButton from './BetSlipButton/BetSlipButton';
import { BonusSelector } from '../../../../state/Bonus/Bonus';
import MyBetsButton from './MyBetsButton/MyBetsButton';

const HeaderInfo = () => {
  const { authenticated, resolved } = useApplicationState();
  const hasActiveDepositBonus = BonusSelector.hasActiveDepositBonus();

  const classes = cx(
    'header__info',
    authenticated ? 'header__info--authenticated' : '',
    hasActiveDepositBonus ? 'header__info--has-bonus' : '',
  );

  if (!resolved) {
    return null;
  }

  const ThemeHeaderInfoAuthorized = Theme.HeaderInfoAuthorized || HeaderInfoAuthorized;

  return (
    <>
      <div className={classes}>
        {authenticated ? <ThemeHeaderInfoAuthorized /> : <HeaderInfoUnauthorized />}
      </div>
      <MyBetsButton />
      <BetSlipButton />
    </>
  );
};

export default HeaderInfo;
