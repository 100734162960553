import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { STATUS } from '@apollo/core/src/constants';
import { RACE_STATUS } from '../../../../core/constants';
import Odds from '../../../../shared/components/Odds';
import Label from '../../../../shared/components/I18n/Label';

const ANIMATION_TIMEOUT_MS = 3000;

const MultilegsOdds = ({ className, race, legs, runner = {}, runners, odds, favorite }) => {
  const prevOddsRef = useRef(_.toNumber(odds));
  const resetDirectionClassTimerRef = useRef(null);
  const [directionClassName, setDirectionClassName] = useState(null);

  const [racingState, racingDispatcher] = useRacingState();
  const { multilegsSelections = [] } = racingState;

  // console.log({ marketsByType });

  useEffect(() => {
    const newOdds = _.toNumber(odds);
    const prevOdds = prevOddsRef.current;
    const dOdds = newOdds - prevOdds;
    prevOddsRef.current = newOdds;

    if (!dOdds) {
      return;
    }

    clearTimeout(resetDirectionClassTimerRef.current);
    setDirectionClassName(dOdds > 0 ? 'up' : 'down');
    resetDirectionClassTimerRef.current = setTimeout(() => {
      setDirectionClassName(null);
    }, ANIMATION_TIMEOUT_MS);
  }, [odds]);

  const isDisabled = () => ![STATUS.ACTIVE, RACE_STATUS.OPEN].includes(race.status) || runner?.scratched;

  const raceIndexInSelections = useMemo(() => legs.indexOf(`${race.number}`), [race, legs]);

  const isSelected = useMemo(
    () => (_.isEmpty(runners)
      ? multilegsSelections[raceIndexInSelections]?.includes(runner?.number)
      : runners
        .filter((r) => !r.scratched)
        .every((r) => multilegsSelections[raceIndexInSelections]?.includes(r?.number))),
    [multilegsSelections, raceIndexInSelections, runner, runners],
  );

  const clickHandler = useCallback(
    (e) => {
      const selections = [...multilegsSelections];
      let selection = selections[raceIndexInSelections] || [];

      if (_.isEmpty(runners)) {
        if (selection.includes(runner.number)) {
          selection.splice(selection.indexOf(runner.number), 1);
        } else {
          selection.push(runner.number);
        }
      } else if (!isSelected) {
        selection = runners.filter((r) => !r.scratched).map((r) => r.number);
      } else {
        selection = [];
      }

      selections[raceIndexInSelections] = selection;

      racingDispatcher({
        type: RacingActions.RACING_STATE_UPDATE,
        payload: {
          multilegsSelections: selections,
        },
      });
      e.stopPropagation();
    },
    [race, runner, multilegsSelections, raceIndexInSelections],
  );

  const outcomeClassName = React.useMemo(
    () => cx(className, 'event__outcome', {
      disabled: isDisabled(),
      selected: isSelected,
      'event__outcome--with-name': Boolean(runner?.name),
    }),
    [className, multilegsSelections, raceIndexInSelections, runner, isDisabled()],
  );
  return (
    <div className='event__market-row-market event__market-row-market--checkbox event__market-row-market--multilegs'>
      <div className={outcomeClassName} {...(!isDisabled() && { onClick: clickHandler })}>
        {isSelected ? null : <Label className='add-label' message='add' />}
      </div>
      {odds ? (
        <div className={`odds ${favorite ? 'odds--fav' : ''}`}>
          <Odds value={odds} decimal={2} minDecimal={2} />
          {directionClassName ? (
            <span className={`icon ${directionClassName} AIcon-angle-${directionClassName}`} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default MultilegsOdds;
