import React from 'react';
import { useApplicationState } from '@apollo/core';
import { isNumber } from 'lodash';
import ToBetHistoryButton from '../../../ToBetHistoryButton/ToBetHistoryButton';

const MyBetsButton = () => {
  const { authenticated, betsCount } = useApplicationState();

  return authenticated ? (
    <ToBetHistoryButton className='btn-my-bets'>
      <div className='badge__container'>
        {isNumber(betsCount.UNSETTLED) ? betsCount.UNSETTLED : 0}
      </div>
    </ToBetHistoryButton>
  ) : null;
};

export default MyBetsButton;
