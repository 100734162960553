import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isInactive, isSuspended, useApplicationState } from '@apollo/core';
import cx from 'classnames';
import _ from 'lodash';
import { DISPLAY_LAYOUTS } from '@apollo/core/src/constants';
import { sportService as sportServices } from '../../../core/constants';
import Odds from '../Odds';
import OutcomeLogo from './OutcomeLogo';
import {
  getEventMainMarket,
  isMarketOddsClickable,
  isOutcomeDefaultOddsAreBoosted,
} from '../../../core/utils';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import PromoOdds from '../Odds/PromoOdds';

const ANIMATION_TIMEOUT_MS = 3000;

const OutcomeView = ({
  className = '',
  event,
  event: { id: eventId, sportService },
  outcome,
  outcome: { id: outcomeId, odds, oddsForMulti, name = '', isFake },
  suspended,
  disabled,
  onClick,
  selected,
  marketType,
  indexInRow,
}) => {
  const { layout } = useApplicationState();
  const prevOddsRef = useRef(_.toNumber(odds));
  const resetDirectionClassTimerRef = useRef(null);
  const [directionClassName, setDirectionClassName] = useState(null);

  const isOutcomeSuspended = suspended || isSuspended(outcome);
  const isOutcomeDisabled = disabled || isInactive(outcome);
  const isArchived = sportService === sportServices.ARCHIVED;
  const isDisabledView = !isArchived && (isOutcomeSuspended || isOutcomeDisabled);

  const selectedPromoOdds = BonusSelector.getPromoOddsByEventIdAndOutcomeId({ eventId, outcomeId })
    // if multiple promo odds defined for the same outcome then take the highest
    .sort((a, b) => b.getFinalOdds() - a.getFinalOdds())[0];

  const promoOdds = selectedPromoOdds?.getFinalOdds();

  const defaultOddsAreBoosted = isOutcomeDefaultOddsAreBoosted(outcome);

  useEffect(() => {
    const newOdds = _.toNumber(odds);
    const prevOdds = prevOddsRef.current;
    const dOdds = newOdds - prevOdds;
    prevOddsRef.current = newOdds;

    if (!dOdds) {
      return;
    }

    // window.clearTimeout(resetDirectionClassTimerRef.current);
    setDirectionClassName(dOdds > 0 ? 'up' : 'down');
    resetDirectionClassTimerRef.current = window.setTimeout(() => {
      setDirectionClassName(null);
    }, ANIMATION_TIMEOUT_MS);
    return () => {
      window.clearTimeout(resetDirectionClassTimerRef.current);
    };
  }, [odds]);

  const isMainMarket = useMemo(
    () => marketType?.id === getEventMainMarket(event)?.marketTypeId,
    [event, marketType],
  );

  const outcomeClassName = cx(className, 'odd-wrapper', directionClassName, {
    disabled: isDisabledView,
    'market--main': isMainMarket,
    'market--odd-box': isMarketOddsClickable(marketType?.displayLayout) || isMainMarket,
  });

  const renderDisabledCover = useMemo(
    () => (isDisabledView ? (
      <div key='suspend' className='disabled-cover'>
        {/* <i className='AkIcon-lock-icon' /> */}
      </div>
    ) : null),
    [isDisabledView],
  );

  const renderDirectionArrow = useMemo(
    () => (directionClassName ? (
      <i key='arrow' className={`arrow AkIcon-long-arrow-${directionClassName}-icon`} />
    ) : null),
    [directionClassName],
  );

  const [isHome, isAway] = useMemo(() => {
    if (
      [
        DISPLAY_LAYOUTS.ODDS_SORTING_TWO_COLUMNS,
        DISPLAY_LAYOUTS.ODDS_SORTING_GROUPED_BY_MARKET,
      ].includes(marketType?.displayLayout)
    ) {
      if (indexInRow === 0) {
        return [true, false];
      }
      if (indexInRow === 1) {
        return [false, true];
      }
      return [];
    }
    return [];
  }, [indexInRow, marketType]);

  const outcomeName = useMemo(() => {
    if (
      layout.mobileDevice
      && marketType?.displayLayout === DISPLAY_LAYOUTS.THREE_COLUMNS_WINNER_SCORE
      && name !== 'Draw'
    ) {
      return outcome?.specifiers || name;
    }
    return name;
  }, [name, marketType?.displayLayout, layout.mobileDevice, outcome]);

  if (isFake) {
    return (
      <span
        className={`market bold bet center name isFake ${className} ${isDisabledView ? 'disabled' : ''}`}
      >
        {name}
      </span>
    );
  }

  if (isOutcomeDisabled && !isOutcomeSuspended && !isArchived) {
    return null;
  }

  const isClickable = isMarketOddsClickable(marketType?.displayLayout) || isMainMarket;

  const oddClassName = cx('odd', {
    selected,
    'odds--archived': isArchived,
    'event__outcome--mobile': layout.mobileDevice,
    'odds__promo-odds': promoOdds || defaultOddsAreBoosted,
    highlighted: promoOdds,
  });

  return (
    <div className={outcomeClassName} onClick={(e) => (isClickable ? null : onClick(e))}>
      <OutcomeLogo
        isAway={isAway}
        isHome={isHome}
        event={event}
        outcomeName={name}
        className='outcome-logo'
      />
      <div className='odd-wrapper__content'>
        {outcomeName ? <span className='name'>{outcomeName}</span> : null}
        <span className={oddClassName} onClick={(e) => (isClickable ? onClick(e) : null)}>
          {renderDirectionArrow}
          {promoOdds ? (
            <PromoOdds odds={odds} promoOdds={promoOdds} />
          ) : defaultOddsAreBoosted ? (
            <PromoOdds odds={oddsForMulti} promoOdds={odds} />
          ) : (
            <Odds value={odds} decimal={3} minDecimal={2} />
          )}
          {renderDisabledCover}
        </span>
      </div>
    </div>
  );
};

export default OutcomeView;
