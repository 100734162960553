import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _, { isString } from 'lodash';
import cx from 'classnames';
import {
  BetManager,
  isInactive,
  isSuspended,
  useApplicationState,
  useHighlightedOutcome,
} from '@apollo/core';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import moment from 'moment';
import {
  EVENT_LIST_TEMPLATE,
  isOutcomeDefaultOddsAreBoosted,
  oddsAnimation,
  overUnderMapping,
} from '../../../core/utils';
import Odds from '../../../shared/components/Odds';
import SportOutcomeLogo from './SportOutcomeLogo';
import { BonusSelector } from '../../../state/Bonus/Bonus';
import PromoOdds from '../../../shared/components/Odds/PromoOdds';

const ANIMATION_TIMEOUT_MS = 3000;

const SportOutcome = ({
  className = '',
  event,
  market,
  outcome,
  outcome: { odds, oddsForMulti },
  marketTypeId,
  marketId,
  name,
  suspended,
  disabled,
  template,
}) => {
  const { selected, placed, editing, loading, failed, succeeded } = useHighlightedOutcome(
    outcome.id,
  );
  const { layout } = useApplicationState();

  const prevOddsRef = useRef(_.toNumber(odds));
  const resetDirectionClassTimerRef = useRef(null);
  const [directionClassName, setDirectionClassName] = useState(null);

  const selectedPromoOdds = BonusSelector.getPromoOddsByEventIdAndOutcomeId({
    eventId: event.id,
    outcomeId: outcome.id,
  })
    // if multiple promo odds defined for the same outcome then take the highest
    .sort((a, b) => b.getFinalOdds() - a.getFinalOdds())[0];

  const promoOdds = useMemo(() => selectedPromoOdds?.getFinalOdds(), [selectedPromoOdds]);

  const defaultOddsAreBoosted = isOutcomeDefaultOddsAreBoosted(outcome);

  const isOutcomeSuspended = suspended || isSuspended(outcome);
  const isOutcomeDisabled = disabled || isInactive(outcome);
  const [eventId, betGroups, startTime] = _.at(event, [
    'id',
    `marketTypes.${marketTypeId}.betGroups`,
    'startTime',
  ]);
  const outcomeId = _.get(outcome, 'id');
  const eventPassedStartTime = startTime && moment().diff(moment(startTime), 'hours') > 24;

  const addToBet = useCallback(
    (e) => {
      if (isOutcomeSuspended || isOutcomeDisabled || eventPassedStartTime) {
        return null;
      }

      if (layout.mobileDevice && !selected) {
        oddsAnimation(
          e.target.classList.contains('event__outcome') ? e.target : e.target.parentNode,
        );
      }
      BetManager.selectBet({
        betEventType: event.sportService || BET_EVENT_TYPE.SPORT_PREMATCH,
        event,
        eventId,
        marketTypeId,
        marketId: marketId || _.get(market, 'id'),
        outcomeId,
        betGroups,
        isNew: true,
      });
    },
    [event, selected, eventId, marketId, outcomeId, marketTypeId, betGroups, isOutcomeSuspended],
  );

  useEffect(() => {
    const newOdds = _.toNumber(odds);
    const prevOdds = prevOddsRef.current;
    const dOdds = newOdds - prevOdds;
    prevOddsRef.current = newOdds;

    if (!dOdds) {
      return;
    }

    // window.clearTimeout(resetDirectionClassTimerRef.current);
    setDirectionClassName(dOdds > 0 ? 'up' : 'down');
    resetDirectionClassTimerRef.current = window.setTimeout(() => {
      setDirectionClassName(null);
    }, ANIMATION_TIMEOUT_MS);

    return () => {
      window.clearTimeout(resetDirectionClassTimerRef.current);
    };
  }, [odds]);

  const outcomeClassName = React.useMemo(
    () => cx(className, 'event__outcome', directionClassName, {
      disabled: isOutcomeSuspended || isOutcomeDisabled || eventPassedStartTime,
      selected,
      editing,
      placed,
      failed,
      loading,
      succeeded,
      'event__outcome--with-name': Boolean(name),
      'event__outcome--mobile': layout.mobileDevice,
      'odds__promo-odds': promoOdds || defaultOddsAreBoosted,
      highlighted: promoOdds,
    }),
    [
      className,
      directionClassName,
      isOutcomeSuspended,
      isOutcomeDisabled,
      selected,
      editing,
      placed,
      failed,
      loading,
      succeeded,
      eventPassedStartTime,
      layout.mobileDevice,
      name,
      promoOdds,
    ],
  );

  // const renderSpecifiers = useCallback(
  //   () => (overUnderMapping[outcome.shortName]
  //     ? outcome.name && <span className='specifiers'>{outcome.name}</span>
  //     : outcome.specifiers && <span className='specifiers'>{outcome.specifiers}</span>),
  //   [outcome],
  // );

  const renderName = useCallback(() => {
    if (!name || !isString(name)) {
      return null;
    }

    // todo: This throws an error when 'name' is not a string
    return (
      <>
        <SportOutcomeLogo name={name} event={event} />
        <span className='name'>{name}</span>
      </>
    );
  }, [name, event]);

  const renderLabel = useCallback(() => {
    if (overUnderMapping[outcome.shortName]) {
      return (
        <>
          <span className='specifiers specifiers-mobile'>
            {`${overUnderMapping[outcome.shortName]} (${outcome.specifiers})`}
          </span>
          {outcome.name && <span className='specifiers specifiers-desc'>{outcome.name}</span>}
        </>
      );
    }

    if (template === EVENT_LIST_TEMPLATE.MULTI_MARKETS) {
      return outcome.specifiers && (
      <span className='specifiers'>
        (
        {outcome.specifiers}
        )
      </span>
      );
    }

    return renderName();
  }, [outcome, template, renderName]);

  if (isOutcomeDisabled && !isOutcomeSuspended) {
    return null;
  }

  return (
    <span className={outcomeClassName} onClick={(e) => addToBet(e)}>
      {layout.mobileDevice && <SportOutcomeLogo name={name} event={event} />}
      <span className='odds-with-label'>
        {renderLabel()}
        {promoOdds ? (
          <PromoOdds className='odds' odds={odds} promoOdds={promoOdds} />
        ) : defaultOddsAreBoosted ? (
          <PromoOdds className='odds' odds={oddsForMulti} promoOdds={odds} />
        ) : (
          <span className='odds'>
            <span>
              <Odds value={odds} decimal={3} minDecimal={2} />
            </span>
          </span>
        )}
      </span>
    </span>
  );
};

export default SportOutcome;
