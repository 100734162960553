import React, { useMemo } from 'react';
import { isString, noop } from 'lodash';
import cx from 'classnames';
import Label from '../components/I18n/Label';

export const POPUP_STATUS = {
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
  Promotion: 'empty',
};

const BasePopup = (props) => {
  const {
    title,
    message,
    children,
    className = '',
    onClose = noop,
    status = POPUP_STATUS.Info,
    withOkButton,
    withCancelButton = false,
    withCloseButton = true,
    icon = null,
    isConfirm,
    onConfirm = noop,
  } = props;

  const renderCloseButton = withCloseButton ? (
    <div className='popup-close-btn' onClick={onClose}>
      <i className='icon__close' title='Close' />
    </div>
  ) : null;

  const renderIcon = useMemo(() => {
    if (!icon) {
      return null;
    }

    switch (icon) {
      case POPUP_STATUS.Info: {
        return <span className='icon-container info'>i</span>;
      }

      case POPUP_STATUS.Success: {
        return <span className='AkIcon-check-icon icon-container success' />;
      }

      case POPUP_STATUS.Error: {
        return <span className='icon-container error'>!</span>;
      }

      case POPUP_STATUS.Promotion:
      default: {
        return null;
      }
    }
  }, [icon]);

  const emptyMode = useMemo(() => POPUP_STATUS.Promotion === status, [status]);

  const renderOkCancelButton = useMemo(() => {
    if (isConfirm || (!withOkButton && !withCancelButton)) {
      return null;
    }

    const classes = cx('block group-button', {
      'has-cancel': withCancelButton,
      'has-ok': withOkButton,
    });

    return (
      <div className={classes}>
        {withCancelButton ? (
          <button type='button' className='bCancel' onClick={onClose}>
            <Label message='cancel' />
          </button>
        ) : null}
        {withOkButton ? (
          <button type='button' className='bBase' onClick={onClose}>
            <Label message='ok' />
          </button>
        ) : null}
      </div>
    );
  }, [isConfirm, withOkButton, withCancelButton, onClose]);

  const renderConfirmButtons = useMemo(() => {
    if (!isConfirm) {
      return null;
    }

    return (
      <div className='block group-button has-cancel has-ok'>
        <button type='button' className='bCancel' onClick={onClose}>
          <Label message='cancel' />
        </button>
        <button type='button' className='bBase' onClick={onConfirm}>
          <Label message='confirm' />
        </button>
      </div>
    );
  }, [isConfirm, onClose, onConfirm]);

  return (
    <div
      className={`popup popup--${status || ''} ${status || ''} ${className} ${isConfirm ? 'popup--confirm' : ''}`}
    >
      <div className='popup__auth'>
        <div className='block'>
          {emptyMode ? (
            <>
              {renderCloseButton}
              {children}
            </>
          ) : (
            <>
              <div className='block__title'>
                <div>
                  {renderIcon}
                  <Label message={title} />
                </div>
                {renderCloseButton}
              </div>
              <div className='block__content'>
                <div className='messages'>
                  {message && isString(message) ? <Label message={message} /> : message}
                  {children}
                </div>
                {renderOkCancelButton}
                {renderConfirmButtons}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default BasePopup;
