import React, { useMemo } from 'react';
import { Bus, constants, SiteConfigManager, useSettings } from '@apollo/core';
import Label from '../I18n/Label';

const LiveChatLink = ({ className = 'sidebar-tree__section-item live-chat' }) => {
  const { isCordova, platform } = useSettings();
  const liveChatConfig = SiteConfigManager.getConfig('config.config.support.live_chat.mobile');
  // const isIOS = useMemo(
  //   () => isCordova && platform?.toUpperCase() === 'IOS',
  //   [isCordova, platform]
  // );

  const liveChatUrl = useMemo(() => {
    if (liveChatConfig?.group_id) {
      return `https://direct.lc.chat/${liveChatConfig?.widget_id}/${liveChatConfig?.group_id}`;
    }
    return `https://direct.lc.chat/${liveChatConfig?.widget_id}/`;
  }, [liveChatConfig]);

  const showLiveChat = React.useCallback(() => {
    if (isCordova) {
      window.open(liveChatUrl, '_system');
      return;
    }
    Bus.send({
      event: Bus.events.layout.setLiveChat,
      data: constants.LIVECHAT_VISIBILITY?.MAXIMIZED,
    });
  }, [isCordova, platform]);

  return (
    <a className={className} onClick={() => showLiveChat()}>
      <Label message='Live Chat' />
    </a>
  );
};

export default LiveChatLink;
