import { isEmpty } from 'lodash';
import React from 'react';
import { isMultilegsMarket } from '@apollo/core';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import BetItemEventLink from '../../../../../shared/components/BetItemEventLink/BetItemEventLink';

const TransactionEventDetailsDesktop = ({
  outcome,
  eventStartTimeDate,
  raceNumbers,
  transaction,
  eventName,
  subTitle,
  marketName,
  flexiPercentage,
}) => {
  const { additionalAttribute1, additionalAttribute2 } = transaction;
  const { marketSpecifiers, sameGameMultiLegs } = outcome;
  const selection = outcome.localizedOutcomeName;
  const isSGM = sameGameMultiLegs?.length > 0;

  const t = useTranslate();

  const formatMultiLegs = (sel) => {
    const arr = sel.split(',').join(', ').split('/');

    function nth(n) {
      return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] ?? 'th';
    }

    return arr.length > 1
      ? arr.map((item, key) => (
        <div key={key} className='event-legs'>
          {`${key + 1}${nth(key + 1)}: `}
          <span>{item}</span>
        </div>
      ))
      : arr[0];
  };

  const getSelections = () => {
    if (!selection) {
      return null;
    }

    if (!isEmpty(sameGameMultiLegs)) {
      return (
        <>
          {sameGameMultiLegs.length}
          {' '}
          {t('legs')}
          {' '}
          {marketName}
        </>
      );
    }

    if (!isMultilegsMarket(outcome.marketTypeId)) {
      return formatMultiLegs(selection);
    }

    return marketName.split(' (')[0];
  };

  return (
    <div>
      <div className='event-details'>
        <div className='start-date'>
          <span className='sub-title'>{eventStartTimeDate}</span>
        </div>
        <BetItemEventLink className='event-name' outcomeInfo={outcome}>
          <div className='event'>
            <h3 className='text-small'>{eventName}</h3>
            <span>{marketName}</span>
            {marketSpecifiers && !isSGM ? (
              <span className='market-specifiers'>
                (
                {marketSpecifiers}
                )
              </span>
            ) : null}
            {getSelections()}
            {additionalAttribute1 === 'extra-desc' ? (
              <span className='text-small extra-desc'>{additionalAttribute2}</span>
            ) : null}
            {sameGameMultiLegs?.length ? (
              <div className='event-legs'>
                {sameGameMultiLegs.map((leg) => (
                  <div key={leg.outcomeId} className='event-leg'>
                    {leg.localizedOutcomeName}
                    <div className='text-small'>{leg.localizedMarketName}</div>
                  </div>
                ))}
              </div>
            ) : null}
            {isMultilegsMarket(outcome.marketTypeId) && raceNumbers.length ? (
              <div className='event-legs'>
                {raceNumbers.map((number, i) => (
                  <div key={number} className='event-leg'>
                    {`R${number}: `}
                    <span className=''>
                      {outcome.outcomeName?.split('/')[i].split(',').join(', ')}
                    </span>
                  </div>
                ))}
              </div>
            ) : null}

            {flexiPercentage ? <span className='text-small'>{flexiPercentage}</span> : null}
          </div>
        </BetItemEventLink>
      </div>
      <span className='sub-title'>{subTitle}</span>
    </div>
  );
  /* }); */
};

export default TransactionEventDetailsDesktop;
