import { SportSelector } from '@apollo/core/src/state/sport/sport';
import React from 'react';
import { isHidden } from '@apollo/core';
import { SPORT_SERVICE } from '@apollo/core/src/constants';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import SportEvents from '../../../../features/Home/Tabs/Sports/SportEvents';

const TopTournament = ({ tournament, isSwipeableCarousel, isBigBoxes, bigBoxesCount }) => {
  const events = SportSelector.selectEventsByTournamentId(tournament.id).filter(
    (e) => !isHidden(e) && hasMarketTypes(e) && e.sportService === SPORT_SERVICE.PREMATCH,
  );

  return (
    <SportEvents
      isSwipeableCarousel={isSwipeableCarousel}
      isBigBoxes={isBigBoxes}
      bigBoxesCount={bigBoxesCount}
      key={tournament.id}
      title={tournament.name}
      sportUrlName={tournament.sportUrlName}
      categoryUrlName={tournament.categoryUrlName}
      tournamentUrlName={tournament.urlName}
      events={events}
    />
  );
};
export default TopTournament;
