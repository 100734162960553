import React, { useCallback } from 'react';

import { Bus, useApplicationState } from '@apollo/core';

import Label from '../../shared/components/I18n/Label';
import CmdLink from '../../shared/components/CmdLink';
import SignUpLink from '../../shared/components/SignUp/SignUpLink';
import StaticBlock from '../../shared/components/StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../core/constants';

const BetslipEmptyState = () => {
  const { layout, authenticated, resolved } = useApplicationState();

  const signUpClick = useCallback(() => {
    if (!layout.desktopDevice) {
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: false,
      });
    }
  }, [layout]);

  const renderAuthButtons = React.useMemo(() => {
    if (authenticated) {
      return (
        <div className='authorized-emty-message'>
          <Label className='empty-state-label authorized' message='no_items_in_betslip_yet' />
          <StaticBlock
            className='betslip__promo-block'
            options={{ source: cmsStaticBlock.BetSlipPromoBlock }}
          />
        </div>
      );
    }

    return (
      <div className='unauthorized-emty-message'>
        <Label className='empty-state-label' message='login_to_start_betting' />
        <div className='empty-state-buttons'>
          <CmdLink className='btn btn-login' cmd='signin'>
            <Label message='login' />
          </CmdLink>
          <SignUpLink onClickHandler={signUpClick} />
        </div>
        <StaticBlock
          className='betslip__promo-block'
          options={{ source: cmsStaticBlock.BetSlipPromoBlock }}
        />
      </div>
    );
  }, [authenticated, signUpClick]);

  if (!resolved) {
    return null;
  }

  return (
    <div className='bets__betslip__empty'>
      <div className='emptyIcon'>
        <span className='icon empty-icon' />
      </div>
      {renderAuthButtons}
    </div>
  );
};

export default BetslipEmptyState;
