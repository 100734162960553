import React from 'react';
import { DateService } from '@apollo/core';
import BetCouponTeams from './BetCouponTeams';
import MultiStatusIcon from './MultiStatusIcon';

const BetCouponDetails = ({ outcome, betType }) => {
  const {
    outcomeInfo: {
      marketName,
      localizedMarketName,
      sameGameMultiLegs,
      eventStartTime,
      marketSpecifiers,
    },
  } = outcome;

  const isSGM = sameGameMultiLegs?.length > 0;

  return (
    <div className='coupon__block-details'>
      <div className='coupon__block'>
        <div className='coupon__outcome'>
          {sameGameMultiLegs?.length ? (
            <div className='event-legs'>
              {sameGameMultiLegs.map((leg) => (
                <div key={leg.outcomeId} className='event-leg'>
                  {leg.localizedOutcomeName}
                  <MultiStatusIcon outcomeInfo={outcome.outcomeInfo} betType={betType} />
                  <div className='text-small'>{leg.localizedMarketName}</div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {localizedMarketName || marketName}
              <MultiStatusIcon outcomeInfo={outcome.outcomeInfo} betType={betType} />
            </>
          )}
        </div>
      </div>
      {marketSpecifiers && !isSGM ? (
        <div className='coupon__block'>
          <div className='coupon__market-specifiers'>
            (
            {marketSpecifiers}
            )
          </div>
        </div>
      ) : null}
      <div className='coupon__block'>
        <BetCouponTeams outcome={outcome} />
      </div>

      <div className='event-time event-time--bet'>
        {DateService.getFormattedDateWithTime(eventStartTime)}
      </div>
    </div>
  );
};

export default BetCouponDetails;
