import React, { useCallback, useEffect, useState } from 'react';
import { get, isArray, map } from 'lodash';
import cx from 'classnames';
import { useApplicationState, usePreferences } from '@apollo/core';
import Label from '../../../../shared/components/I18n/Label';
import IconFavorite from '../../../../shared/components/IconFavorite/IconFavorite';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { sportProviders } from '../../../../core/constants';
import { AnimatedDropdown } from '../../../Animations/AnimatedDropdown';
import MarketTypeDescription from './MarketTypeDescription';
import MarketTypeRows from './MarketTypeRows';

const MarketTypeDefaultView = ({
  event,
  marketType,
  isFavorite,
  isArchived,
  data,
  marketTypesShowState,
  setMarketTypesShowState,
  isBadgeHeaderHidden,
  isSgm,
  activeGroup,
}) => {
  const t = useTranslate();

  const preferences = usePreferences();

  const [isExpanded, setIsExpanded] = useState(() => {
    const savedState = get(
      marketTypesShowState,
      `${event.sportId}.${activeGroup?.text}.${marketType.id}`,
    );

    if (savedState !== undefined) {
      return savedState;
    }

    return activeGroup?.group ? marketType?.show : marketType?.groups?.includes('MAIN');
  });

  useEffect(() => {
    setIsExpanded(
      get(
        marketTypesShowState,
        `${event.sportId}.${activeGroup?.text}.${marketType.id}`,
        activeGroup?.group ? marketType?.show : marketType?.groups?.includes('MAIN'),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroup, event.sportId, marketType]);

  const { selectFavoriteMarketType } = isArchived ? {} : preferences;
  const applicationState = useApplicationState();
  const authenticated = get(applicationState, 'authenticated');

  const selectFavoriteMarketTypeHandler = useCallback(
    (event) => {
      event.stopPropagation();

      selectFavoriteMarketType({ marketTypeId: marketType.id });
    },
    [marketType.id, selectFavoriteMarketType],
  );

  const toggleExpand = () => {
    if (!isArchived) {
      setMarketTypesShowState((prevState) => ({
        ...prevState,
        [event?.sportId]: {
          ...prevState[event?.sportId],
          [activeGroup?.text]: {
            ...prevState[event?.sportId]?.[activeGroup?.text],
            [marketType.id]: !isExpanded,
          },
        },
      }));
    }
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const showSgmIcon = React.useMemo(
    () => Boolean(marketType.providerName === sportProviders.Sportcast || isSgm),
    [isSgm, marketType.providerName],
  );

  const marketTitleClassName = cx('marketGroup__title', { isExpanded });

  return (
    <div
      className={cx(
        'eventView__market',
        marketType.displayLayout
          ? `eventView__market--${marketType.displayLayout.toLowerCase()}`
          : '',
      )}
    >
      <div className={cx('marketGroup')}>
        <div
          className={marketTitleClassName}
          onClick={toggleExpand}
          title={`id:${marketType.id}:${marketType.providerName?.slice(0, 2)}`}
        >
          <span className='name'>{marketType.name}</span>
          {!isBadgeHeaderHidden && (
            <Label
              message='bet group title'
              render={(translation) => (
                <span className='relatedMarket' title={translation}>
                  <span className='relatedMarket__icon AkIcon-link-icon' />
                  <If condition={marketType.betGroups}>
                    <span className='relatedMarket__content'>
                      {
                        // temporary decision before core lib will be fixed
                        isArray(marketType.betGroups)
                          ? marketType.betGroups.join(', ')
                          : Object.values(marketType.betGroups).join(', ')
                      }
                    </span>
                  </If>
                </span>
              )}
            />
          )}
          {authenticated && !isBadgeHeaderHidden && (
            <IconFavorite isActive={isFavorite} onClick={selectFavoriteMarketTypeHandler} />
          )}
          {showSgmIcon && <span className='icons icon--sgm-green' title={t('sgm')} />}
          <span className='icon AIcon-angle-right' />
        </div>
        <AnimatedDropdown isExpanded={isExpanded} className='marketGroup__rows expanded'>
          <MarketTypeDescription marketTypeId={marketType.id} />
          <div className='MarketTypeDefaultView'>
            {(data?.subs || [data]).map((data, i) => (
              <>
                <If condition={data?.header?.length}>
                  <div className='MarketTypeDefaultView__competitors-block '>
                    {map(data.header, (v, index) => {
                      if (!v) {
                        return <div key={index} className='MarketTypeDefaultView__cell' />;
                      }
                      return (
                        <div key={index} className='MarketTypeDefaultView__cell'>
                          {v}
                        </div>
                      );
                    })}
                  </div>
                </If>
                <MarketTypeRows
                  rows={data.rows}
                  event={event}
                  marketType={marketType}
                  isArchived={isArchived}
                  isSgm={isSgm}
                />
              </>
            ))}
          </div>
        </AnimatedDropdown>
      </div>
    </div>
  );
};

export default MarketTypeDefaultView;
