import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { centsToDecimal, DateService } from '@apollo/core';
import cx from 'classnames';
import { BET_TYPE, BONUS_TYPE, RaceLogicMarkets } from '@apollo/core/src/constants';
import BetSourceTooltip from './BetSourceTooltip';
import Label from '../../../../../shared/components/I18n/Label';
import Cashout from '../../../../../shared/components/Cashout/WithPopups/Cashout';
import DateTime from '../../../../../shared/components/DateTime/DateTime';
import HistoryTransactionList from '../../HistoryTransactionList';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import BetSubItemViewSgm from '../../../../Betslip/BetList/BetSubItemViewSgm';
import {
  checkForExotic,
  checkForMultilegs,
  getCurrencySymbol,
  getLegsFromSgmOutcome,
  getRaceNumbersFromOutcomeInfo,
} from '../../../../../core/utils';
import BetItemEventLink from '../../../../../shared/components/BetItemEventLink/BetItemEventLink';
import BetHistoryFreeBetBonus from '../BetHistoryFreeBetBonus';
import MultiStatusIcon from '../../../../Betslip/MyBets/MultiStatusIcon';
import BetCouponOdds from '../../../../Betslip/MyBets/BetCouponOdds';

const renderOrderCashoutIcon = (t) => <span title={t} className='AkIcon-cashout-icon' />;

const BetHistorySingleRow = ({ bet, replaceBet, setOrderCashoutInfoToBet }) => {
  const {
    betId,
    outcomes,
    betStatus,
    originalBets,
    orderCashoutInfo,
    createTime,
    amount,
    betMoney: { currency, payout },
    estimatedCashOut,
    estimatedPartialCashOut,
    minPartialCashOut,
    cashOutCoefficient,
    bonusInfoList,
    betOdds,
    betType,
    updatedOdds,
  } = bet;

  const isExoticOrMultilegs = useMemo(() => checkForExotic(bet) || checkForMultilegs(bet), [bet]);

  const outcome = _.head(outcomes);
  const { outcomeInfo, awayScore, homeScore } = outcome;

  const {
    sportName,
    localizedSportName,
    categoryName,
    localizedCategoryName,
    tournamentName,
    localizedTournamentName,
    eventName,
    localizedEventName,
    marketName,
    sameGameMultiLegs,
    marketSpecifiers,
    localizedMarketName,
    outcomeName,
    localizedOutcomeName,
    eventStartTime,
    finalAwayScore,
    finalHomeScore,
    outcomeStatus,
    marketTypeId,
  } = outcomeInfo;

  const isSGM = sameGameMultiLegs?.length > 0;

  const t = useTranslate();
  const localSportName = localizedSportName || sportName || '';
  const localCategoryName = localizedCategoryName || categoryName || '';
  const localTournamentName = localizedTournamentName || tournamentName || '';
  const localEventName = localizedEventName || eventName || '';

  const desiredCashOutAmount = _.get(orderCashoutInfo, 'desiredCashOutAmount');

  const finalBetScore = useMemo(
    () => (_.isNumber(finalHomeScore) && _.isNumber(finalAwayScore)
      ? `${_.toNumber(finalHomeScore)}:${_.toNumber(finalAwayScore)}`
      : ''),
    [finalHomeScore, finalAwayScore],
  );

  const initialBetScore = useMemo(
    () => (_.isNumber(homeScore) && _.isNumber(awayScore)
      ? `${_.toNumber(homeScore)}:${_.toNumber(awayScore)}`
      : ''),
    [homeScore, awayScore],
  );

  const eventStartTimeDate = DateService.getFormattedDateWithTime(eventStartTime);
  const decimalAmount = centsToDecimal(amount);
  const currencySymbol = getCurrencySymbol(currency);
  const decimalWinAmount = payout ? `${currencySymbol}${centsToDecimal(payout)}` : '-';
  const isSameGameMultiple = useMemo(
    () => bet.outcomes.some(({ outcomeInfo: _ }) => _.marketName === 'Same Game Multi'),
    [bet.outcomes],
  );
  const getLegs = useCallback((outcome) => getLegsFromSgmOutcome(outcome), []);

  const historyRowClassName = cx(`history-table-row bet-history ${betStatus}`, {
    'with-list': !_.isEmpty(originalBets),
    'history-table-row__same-game-multiple': isSameGameMultiple,
  });

  const flexiPercentage = React.useMemo(() => {
    if (isExoticOrMultilegs) {
      return `(${bet.outcomes[0].outcomeInfo.combos} ${t('Combos').replace(':', '')},
      ${Number.parseFloat(bet.betMoney.betUnitAmount).toFixed(3)}${t('betslip_percentage')})`.toLowerCase();
    }
    return '';
  }, [bet, isExoticOrMultilegs, t]);

  const raceNumbers = getRaceNumbersFromOutcomeInfo(outcomeInfo);

  const bonusType = useMemo(() => bonusInfoList?.[0]?.bonusType || null, [bonusInfoList]);

  const betUpdatedOdds = useMemo(
    () => (betType === BET_TYPE.SINGLE
      && bonusInfoList.some((bonus) => [BONUS_TYPE.ODDS_BOOST, BONUS_TYPE.PROMO_ODDS].includes(bonus.bonusType))
      ? { odds: betOdds }
      : updatedOdds?.find((o) => `${o.outcomeId}` === outcome.outcomeId)),
    [updatedOdds, betType, betOdds, bonusInfoList, outcome?.outcomeId],
  );

  const formatMultiLegs = (sel, outcomeInfo) => {
    const arr = outcomeInfo?.marketTypeId === RaceLogicMarkets.SAME_RACE_MULTI
      ? sel.split(',')
      : sel.split('/');

    function nth(n) {
      return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] ?? 'th';
    }

    return arr.length > 1
      ? arr.map((item, key) => (
        <div key={key} className='event-legs'>
          {outcomeInfo?.marketTypeId !== RaceLogicMarkets.SAME_RACE_MULTI
            ? `${key + 1}${nth(key + 1)}: `
            : null}
          <span>{item}</span>
          <MultiStatusIcon outcomeInfo={outcomeInfo} outcomePosition={key} />
        </div>
      ))
      : arr[0];
  };

  return (
    <div className={historyRowClassName}>
      <div className='bet-history-row'>
        <div className='history-table-cell date'>
          {desiredCashOutAmount && (
            <Label message='waiting_for_order_cashout' render={renderOrderCashoutIcon} />
          )}
          <span className='date-id'>
            <DateTime dateTime={createTime} />
          </span>
          <span className='sub-title'>
            #
            {betId}
          </span>
        </div>

        <div className='history-table-cell date'>
          <div className='event-with-score'>
            <div className='start-date'>
              <span className='sub-title'>{eventStartTimeDate}</span>
            </div>
            <BetItemEventLink className='event-name' outcomeInfo={outcomeInfo}>
              {initialBetScore && <span className='score'>{initialBetScore}</span>}
              <span className='event'>
                {checkForMultilegs(bet)
                  ? `${localizedEventName.split(' R')[0]} (${raceNumbers.join(', ')})`
                  : localEventName}
              </span>
            </BetItemEventLink>
          </div>
          {isSameGameMultiple && (
            <BetSubItemViewSgm key={outcome.outcomeId} sgmLegsDataDefault={getLegs(outcome)} />
          )}
          <span className='sub-title'>
            {`${localSportName} - ${localCategoryName} - ${localTournamentName}`}
          </span>
        </div>
        <div className='history-table-cell can-cashout'>
          <div className='col'>
            <span className='market-name'>{localizedMarketName || marketName}</span>
            {marketSpecifiers && !isSGM ? (
              <span className='market-specifiers'>
                (
                {marketSpecifiers}
                )
              </span>
            ) : null}
            {flexiPercentage ? <span className='market-name'>{flexiPercentage}</span> : null}
            <span className='sub-title'>
              {checkForMultilegs(bet) && raceNumbers.length ? (
                <div className='event-legs'>
                  {raceNumbers.map((number, i) => (
                    <div key={number} className='event-leg'>
                      {`R${number}: `}
                      <span className=''>{outcomeName?.split('/')[i].split(',').join(', ')}</span>
                      <MultiStatusIcon outcomeInfo={outcome.outcomeInfo} outcomePosition={i} />
                    </div>
                  ))}
                </div>
              ) : (
                formatMultiLegs(localizedOutcomeName || outcomeName, outcome.outcomeInfo)
              )}
            </span>
          </div>

          <div className='cashout-container'>
            <Cashout
              betId={betId}
              amount={amount}
              odds={betOdds}
              estimatedCashOut={estimatedCashOut}
              estimatedPartialCashOut={estimatedPartialCashOut}
              minPartialCashOut={minPartialCashOut}
              currencySymbol={currencySymbol}
              replaceBet={replaceBet}
              cashOutCoefficient={cashOutCoefficient}
              setOrderCashoutInfoToBet={setOrderCashoutInfoToBet}
            />
          </div>
        </div>

        {/* <div className='history-table-cell bet-source'>
          {
            finalBetScore
              ? (
                <span className='final-score' onClick={onClickEventName}>
                  {finalBetScore}
                </span>
              )
              : <span className='empty-score'>-:-</span>
          }
        </div> */}
        <div className='history-table-cell'>
          <span className='bet'>
            <BetSourceTooltip bonusInfoList={bonusInfoList} />
            <div className={`amount amount--${betStatus}`}>
              {`${currencySymbol}${decimalAmount}`}
            </div>
          </span>
        </div>
        <div className='history-table-cell'>
          <div className='bet-odds odds'>
            {/* <TransactionOdds
              betOdds={betOdds}
              outcomes={outcomes}
              originalBetOdds={originalBetOdds}
            /> */}
            <BetCouponOdds
              updatedOdds={betUpdatedOdds}
              bonusInfoList={bonusInfoList}
              betType={betType}
              outcome={outcomes[0]}
            />
          </div>
        </div>
        <div className='history-table-cell to-return'>
          <span className='bet'>{decimalWinAmount}</span>
          {desiredCashOutAmount && (
            <span className='cashout-info'>
              <Label message='waiting_for_order_cashout' render={renderOrderCashoutIcon} />
              {currencySymbol}
              {centsToDecimal(desiredCashOutAmount)}
            </span>
          )}
        </div>
        <div className='history-table-cell status-label'>
          <Label message={betStatus} />
        </div>
      </div>
      <HistoryTransactionList originalBets={originalBets} />
      {bonusInfoList?.length && bonusInfoList[0].bonusType === BONUS_TYPE.BET_RETURN ? (
        <div className='bet-history-row'>
          <div className='bonus__description'>{bonusInfoList[0].props?.description}</div>
          <BetHistoryFreeBetBonus bet={bet} />
        </div>
      ) : null}
    </div>
  );
};

export default BetHistorySingleRow;
