import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { RaceMarketsTabs } from '@apollo/core/src/constants';
import { RacingManager, SiteConfigManager, useApplicationState } from '@apollo/core';
import Theme from 'themeSource/custom';
import WinPlaceRacePage from './WinPlaceRacePage';
import SameRaceMultiPage from './srmMarket/SameRaceMultiPage';
import CombinedMarketPage from './combinedMarket/CombinedMarketPage';
import MainBody from '../../../shared/components/MainBody/MainBody';
import ExoticMarketPage from './exoticMarket/ExoticMarketPage';
import MultilegsMarketPage from './MultilegsMarket/MultilegsMarketPage';
import SpeedMap from './runnerStatic/SpeedMap';
import Loader from '../../../shared/components/Loader';
import { generateRaceLink } from '../../../core/utils';
import RacingBreadcrumbs from '../RacingBreadcrumbs';
import { RaceMainTabs } from '../../../core/constants';
import { useBonusState } from '../../../state/Bonus/Bonus';
import BonusBanner from '../../../shared/components/Bonus/BonusBanner';
import SwipeableTab from './SwipeableTab';
import RacePageHead from './RacePageHead';
import {
  defaultMainTab,
  FixedExotics,
  getMarketLegsFromMarket,
  getMarketLegsFromMarketType,
  handleSortRunners,
  isMultiLegsByMarketTypeId,
  MainTabsPreferOrder,
  prodWhiteList,
} from './utils';

const RacePage = () => {
  const { push: historyPush } = useHistory();

  const enableExotics = SiteConfigManager.getConfig('config.config.racing.enableExotics');

  // const [activeTab, setActiveTab] = useState(defaultMainTab);
  const [displaySettings, setDisplaySettings] = useState({
    isFlucs: true,
    isSpeedMap: false,
    isForm: false,
  });

  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const [raceStatic, setRaceStatic] = useState({});
  const [racingState, racingDispatcher] = useRacingState();

  const {
    raceDate,
    raceType,
    raceCountry,
    raceVenue,
    raceNumber,
    activeMarketTypeId,
    activeRace,
    activeRaceId,
    activeMeet,
    marketsByType,
    loading,
    marketTab = defaultMainTab,
  } = racingState;

  const [bonusState] = useBonusState();
  const { authenticated } = useApplicationState();
  const { betReturnsByEventId } = bonusState;

  // Can't use state selector. Throws "a change in the order of Hooks" exception
  const hasActiveBetReturn = useCallback((id) => !!betReturnsByEventId[id], [betReturnsByEventId]);

  const setActiveMarketType = useCallback(
    (tab) => {
      racingDispatcher({
        type: RacingActions.RACING_STATE_UPDATE,
        payload: {
          activeMarketTypeId: tab,
        },
      });
    },
    [racingDispatcher],
  );

  const activeMultiLegs = useMemo(
    () => getMarketLegsFromMarket(marketsByType[activeMarketTypeId]),
    [activeMarketTypeId, marketsByType],
  );

  const runners = useMemo(() => handleSortRunners(activeRace?.runners || []), [activeRace]);

  const availableMarketTypes = useMemo(() => {
    const markets = activeRace?.markets;
    return markets
      ?.filter((m) => enableExotics || prodWhiteList.includes(m.type))
      .reduce(
        (list, item) => ({
          ...list,
          [item.typeUiPosition]: {
            ...list[item.typeUiPosition],
            [`${item.type}${item.legs ? `:${item.legs}` : ''}`]: item,
          },
        }),
        {},
      );
  }, [enableExotics, activeRace?.markets]);

  const availableMultilegs = useMemo(() => {
    const uniqAvailableMultilegs = Object.keys(
      availableMarketTypes?.[RaceMainTabs.MULTILEGS_TAB] || {},
    ).reduce((list, marketType) => [...list, ...getMarketLegsFromMarketType(marketType)], []);
    return uniqAvailableMultilegs.sort((a, b) => a - b);
  }, [availableMarketTypes]);

  const [marketTabs, marketSubTabs] = React.useMemo(() => {
    const tabs = Object.keys(availableMarketTypes || {})
      .filter((tabKey) => tabKey !== RaceMarketsTabs.EXTRA)
      .sort((a, b) => MainTabsPreferOrder.indexOf(a) - MainTabsPreferOrder.indexOf(b));

    const subTabs = tabs
      .filter((tab) => tab !== RaceMainTabs.WP_TAB)
      .reduce(
        (list, tab) => ({
          ...list,
          [tab]: Object.keys(availableMarketTypes?.[tab] || {})
            .filter((subTab) => !FixedExotics.includes(subTab))
            .sort((a, b) => {
              const orderA = Number(marketsByType?.[a]?.order || 0);
              const orderB = Number(marketsByType?.[b]?.order || 0);
              return orderA - orderB;
            }),
        }),
        {},
      );

    return [tabs, subTabs];
  }, [availableMarketTypes, marketsByType]);

  useEffect(() => {
    if (activeMarketTypeId && isMultiLegsByMarketTypeId(activeMarketTypeId)) {
      racingDispatcher({
        type: RacingActions.RACING_STATE_UPDATE,
        payload: {
          multilegsSelections: [],
        },
      });
    }
  }, [activeMarketTypeId]);

  useEffect(() => {
    if (activeMeet) {
      racingDispatcher({
        type: RacingActions.RACING_STATE_UPDATE,
        payload: {
          multilegsSelections: [],
        },
      });
    }
  }, [activeMeet?.meetingId]);

  useEffect(() => {
    setIsShowSpinner(true);

    racingDispatcher({
      type: RacingActions.RACING_STATE_UPDATE,
      payload: {
        srmSelections: [],
        combinedSelections: [],
        quinSelections: [],
        exactaSelections: [],
        trifectaSelections: [],
        first4Selections: [],
      },
    });
  }, [activeRaceId]);

  useEffect(() => {
    if (!loading && isShowSpinner) {
      setIsShowSpinner(false);
    }
  }, [loading]);

  useEffect(() => {
    setRaceStatic(null);
    const acRaceStatic = new AbortController();
    if (authenticated && activeRaceId) {
      RacingManager.getRaceStatic(activeRaceId, acRaceStatic.signal)
        .then((data) => {
          setRaceStatic(data.race);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => {
      if (acRaceStatic) {
        acRaceStatic.abort();
      }
    };
  }, [authenticated, activeRaceId]);

  useEffect(() => {
    if (!availableMarketTypes?.[marketTab]) {
      return;
    }
    if (marketTab === RaceMainTabs.MULTILEGS_TAB) {
      // Exact match
      let multiLegMarketType = marketSubTabs[marketTab]
        ?.filter((mt) => mt === activeMarketTypeId)
        .find((marketType) => {
          const legs = getMarketLegsFromMarketType(marketType);
          return legs.includes(raceNumber?.toString());
        });

      if (!multiLegMarketType) {
        // Closest match
        multiLegMarketType = marketSubTabs[marketTab]?.find((marketType) => {
          const legs = getMarketLegsFromMarketType(marketType);
          return legs.includes(raceNumber?.toString());
        });
      }

      const number = multiLegMarketType
        ? raceNumber
        : getMarketLegsFromMarketType(marketSubTabs[marketTab]?.[0])?.[0];

      const type = multiLegMarketType || marketSubTabs[marketTab]?.[0];

      if (number === raceNumber && activeMarketTypeId === type) {
        return;
      }

      historyPush(
        generateRaceLink({
          raceDate,
          raceType,
          raceCountry,
          raceVenue,
          raceNumber: number,
          marketTab,
          marketType: type,
        }),
      );
    } else if (!activeMarketTypeId) {
      setActiveMarketType(marketSubTabs[marketTab]?.[0]);
    }
  }, [activeMarketTypeId, availableMarketTypes, marketTab, marketSubTabs, raceNumber]);

  const renderTab = useMemo(() => {
    switch (marketTab) {
      case RaceMainTabs.SRM_TAB:
        return (
          <SameRaceMultiPage
            runners={runners}
            raceStatic={raceStatic}
            displaySettings={displaySettings}
          />
        );

      case RaceMainTabs.COMBINED_TAB:
        return (
          <CombinedMarketPage
            marketData={marketsByType.F_WIN}
            activeMeet={activeMeet}
            runners={runners}
            raceStatic={raceStatic}
            displaySettings={displaySettings}
          />
        );

      case RaceMainTabs.EXOTICS_TAB:
        return (
          <ExoticMarketPage
            marketData={marketsByType.F_WIN}
            marketType={activeMarketTypeId}
            runners={runners}
            raceStatic={raceStatic}
            displaySettings={displaySettings}
          />
        );

      case RaceMainTabs.MULTILEGS_TAB:
        return (
          <MultilegsMarketPage
            marketData={marketsByType.F_WIN}
            legs={activeMultiLegs}
            selectedBetType={activeMarketTypeId}
            runners={runners}
            raceStatic={raceStatic}
            displaySettings={displaySettings}
          />
        );

      case RaceMainTabs.WP_TAB:
      default:
        return (
          <WinPlaceRacePage
            extraMarkets={availableMarketTypes?.[RaceMarketsTabs.EXTRA] || []}
            activeMeet={activeMeet}
            runners={runners}
            raceStatic={raceStatic}
            displaySettings={displaySettings}
            availableMultilegs={availableMultilegs}
            activeMultiLegs={activeMultiLegs}
          />
        );
    }
  }, [
    raceStatic,
    activeMultiLegs,
    displaySettings,
    runners,
    activeMeet,
    marketsByType,
    marketSubTabs,
    marketTab,
    activeMarketTypeId,
  ]);

  const ThemeRacePageHead = Theme.RacePageHead || RacePageHead;

  return (
    <MainBody className='main__body--race-page'>
      <RacingBreadcrumbs />
      {activeMeet ? (
        <>
          <ThemeRacePageHead
            displaySettings={displaySettings}
            setDisplaySettings={setDisplaySettings}
            marketTabs={marketTabs}
            marketSubTabs={marketSubTabs}
            activeMultiLegs={activeMultiLegs}
            availableMultilegs={availableMultilegs}
          />

          {displaySettings.isSpeedMap ? (
            <SpeedMap activeRace={activeRace} runners={raceStatic?.runners} />
          ) : null}

          {hasActiveBetReturn(activeRace.raceId) ? <BonusBanner race={activeRace} /> : null}

          {isShowSpinner ? <Loader /> : <SwipeableTab>{renderTab}</SwipeableTab>}
        </>
      ) : (
        <Loader />
      )}
    </MainBody>
  );
};

export default RacePage;
