import React, { useMemo, useEffect, useRef } from 'react';
import cx from 'classnames';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import Label from '../../../shared/components/I18n/Label';

const FilterBySport = ({ selectedSportId, onChange, className }) => {
  const sports = SportSelector.selectSports();
  const listRef = useRef(null);

  const sortedSports = useMemo(
    () => sports.filter((s) => s.prematchCount > 0).sort((a, b) => a.name.localeCompare(b.name)),
    [sports],
  );

  useEffect(() => {
    if (listRef.current) {
      const list = listRef.current;
      const activeItem = document.getElementById('active-sports-filter');

      const scrollLeft = activeItem.getBoundingClientRect().left - 50;
      list.scrollTo({ left: scrollLeft });
    }
  }, [selectedSportId]);

  const filterClassName = cx('sports-filter', className);

  return (
    <div className={filterClassName}>
      <div className='sports-filter__container'>
        <div className='sports-filter__list' ref={listRef}>
          <div className='sports-filter__list-item'>
            <div
              id={!selectedSportId ? 'active-sports-filter' : ''}
              className={cx('sport-filter-option', { active: !selectedSportId })}
              onClick={() => onChange(null)}
            >
              <div className='sport-filter-option__container sport-filter-option__container--name'>
              <div className='sport-filter-option__icon'>
              <i class="icons AkIcon-more-icon"></i>

                  </div>
                <div className='sport-filter-option__name'>
                  <Label message='all' />
                </div>
              </div>
            </div>
          </div>
          {sortedSports.map((sport) => (
            <div className='sports-filter__list-item' key={sport.id}>
              <div
                id={selectedSportId === sport.id ? 'active-sports-filter' : ''}
                className={cx('sport-filter-option', { active: selectedSportId === sport.id })}
                onClick={() => onChange(sport.id)}
              >
                <div className='sport-filter-option__container sport-filter-option__container--name'>
                  <div className='sport-filter-option__icon'>
                    <span className={`icon sportIcon sportIcon-${sport.id}`} title={sport.name} />
                  </div>
                  <div className='sport-filter-option__name'>{sport.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterBySport;
