import React from 'react';
import { constants } from '@apollo/core';
import _ from 'lodash';
import Label from '../../../shared/components/I18n/Label';
import Svg from '../../../shared/components/svg/Svg';
import BetOdds from './BetOdds';
import SportOutcomeLogo from '../../Sports/SportOutcome/SportOutcomeLogo';
import { isOutcomeDefaultOddsAreBoosted } from '../../../core/utils';

const { DISPLAY_LAYOUTS, BET_TYPE } = constants;

const ANIMATION_TIMEOUT_MS = 3000;

// FIXME: Needs refactoring
const betslipNameParser = ({ name, specifiers }, { displayLayout }, { sportId, outright }) => {
  try {
    if (outright) {
      return name || specifiers;
    }

    if (['point_winner', 'game_to_deuce', 'game_score'].includes(displayLayout)) {
      let paramsArr = specifiers.split('/');

      paramsArr = paramsArr.map((param, index) => {
        if (param) {
          param = Math.round(param);
        }
        if (index === paramsArr.length - 1) {
          // last param
          if ([2].includes(sportId) && paramsArr.length === 2) return `${param}G`;
          if (displayLayout === DISPLAY_LAYOUTS.GAME_SCORE) return `to ${param}`;
          return `${param}Pt`;
        }
        if (index === 0) {
          if (
            [2, 6, 17].includes(sportId)
            || ([17].includes(sportId) && paramsArr.length === 2)
            || displayLayout === 'game_to_deuce'
          ) return `${param}S`;
          return `${param}G`;
        }
        return `${param}G`;
      });

      return `${paramsArr.join(' ')}`;
    }
    return name;
  } catch (e) {
    console.log('e', e);
    return name;
  }
};

const BetItemViewSport = (props) => {
  const {
    marketType,
    outcome,
    participants,
    event,
    info,
    prevOutcome = {},
    disabled,
    suspended,
    loading,
    market,
    oddsToDisplay,
    assignedBonus,
    betType,
  } = props;

  const [prevOutcomeName, setPrevOutcomeName] = React.useState(prevOutcome.name);
  const prevOutcomeNameTimerRef = React.useRef(null);

  React.useEffect(() => {
    if (_.isEmpty(prevOutcome)) return;
    setPrevOutcomeName(prevOutcome.name);

    // window.clearTimeout(prevOutcomeNameTimerRef.current);
    prevOutcomeNameTimerRef.current = window.setTimeout(() => {
      setPrevOutcomeName(null);
    }, ANIMATION_TIMEOUT_MS);
    return () => {
      window.clearTimeout(prevOutcomeNameTimerRef.current);
    };
  }, [prevOutcome.id]);

  const score = React.useMemo(() => {
    const homeScore = _.get(info, 'score.homeScore');
    const awayScore = _.get(info, 'score.awayScore');

    if (Number.isInteger(homeScore) && Number.isInteger(awayScore)) {
      return `${homeScore}:${awayScore}`;
    }

    return null;
  }, [info]);

  const eventName = React.useMemo(() => {
    if (event.outright) {
      return (
        <div className='event__team'>
          <div>{event.name}</div>
        </div>
      );
    }

    const homeFullName = _.get(participants, 'home.fullName', '');
    const awayFullName = _.get(participants, 'away.fullName', '');

    return (
      <div className='event__team'>
        <span>{homeFullName}</span>
        {' '}
        <Label message='vs' />
        {' '}
        <span>{awayFullName}</span>
      </div>
    );
  }, [participants, event]);

  const warningWillBeRemoved = disabled && !suspended && !loading;

  const isMultiBet = betType === BET_TYPE.MULTIPLE;

  return (
    <>
      <div className='event event--sport'>
        <div className='event__name'>
          <div className='event-icon'>
            <span className={`icon sportIcon sportIcon-${event.sportId}`} title={event.sportName} />
          </div>
          <div>{eventName}</div>
        </div>
        <div className='event__outcome-container'>
          <SportOutcomeLogo name={outcome.name} event={event} className='event__outcome-logo'>
            <span className='event__outcome-logo event__outcome-logo--empty' />
          </SportOutcomeLogo>
          <div className='event__outcome-details'>
            <div className='event__outcome-name'>
              {prevOutcomeName && <div className='outcomeName_changed'>{prevOutcomeName}</div>}
              {betslipNameParser(outcome, marketType, event)}
              {market.specifiers && event.sportId === 19 ? ( // cricket only
                <span className='event__market-name--specifiers'>
                  &nbsp;(
                  {market.specifiers?.split('#')[0]}
                  )
                </span>
              ) : null}
            </div>
            <div className='event__market-name'>
              {event.sportService === constants.SPORT_SERVICE.LIVE && score && (
                <span className='event__score'>
                  {score}
                  {' '}
                  |
                  {' '}
                </span>
              )}
              <span className='event__market-name--name'>{marketType.name}</span>
            </div>
          </div>
        </div>
        {event.onlySingle && (
          <div className='warning hide'>
            <Svg name='warning' />
            <Label message='bet_only_single' />
          </div>
        )}
        {warningWillBeRemoved && (
          <div className='warning'>
            <Svg name='warning' />
            <Label message='bet_is_inactive' />
          </div>
        )}
      </div>

      {/* <div className='info'>
        {prevOutcomeName && (
          <div className='outcomeName_changed'>{prevOutcomeName}</div>
        )}
        <div>
          {betslipNameParser(outcome, marketType, event)}
        </div>

      </div> */}

      <BetOdds
        outcomeId={outcome.id}
        odds={outcome.odds}
        oddsToDisplay={
          isMultiBet && isOutcomeDefaultOddsAreBoosted(outcome)
            ? // when regular odds are considered "boosted" then multi needs to show "regular" odds
          // which in this case are "oddsForMulti"
            outcome.oddsForMulti
            : oddsToDisplay
        }
        assignedBonus={assignedBonus}
      />
    </>
  );
};

export default BetItemViewSport;
