import React, { useMemo } from 'react';
import { isEmpty, map } from 'lodash';
import { DedicatedEventProvider, isHidden, useApplicationState } from '@apollo/core';
import { SPORT_SERVICE } from '@apollo/core/src/constants';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import { Link } from 'react-router-dom';
import TopEvent from '../../../Sports/TopEvents/TopEvent/TopEvent';
import EventCarousel from './EventCarousel';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { SPORTS_TABS, TournamentLink } from '../../../../shared/components/Sport/SportLinks';
import { getEventMainMarket } from '../../../../core/utils';
import Scrollbar from '../../../../shared/components/Scrollbar/Scrollbar';

const SportEvents = ({
  title,
  sportUrlName,
  categoryUrlName,
  tournamentUrlName,
  events,
  linkHash,
  isSwipeableCarousel = false,
  isBigBoxes = false,
  bigBoxesCount = 2,
}) => {
  const { layout } = useApplicationState();
  const t = useTranslate();

  const boxesCount = parseInt(bigBoxesCount) > 0 ? parseInt(bigBoxesCount) : 2;

  const filteredEvents = useMemo(
    () => events?.filter((e) => {
      if (e.sportService !== SPORT_SERVICE.PREMATCH) {
        return false;
      }
      if (isHidden(e) || !hasMarketTypes(e)) {
        return false;
      }

      return !!getEventMainMarket(e);
    }) || [],
    [events],
  );

  if (isEmpty(filteredEvents)) {
    return null;
  }

  return (
    <div className='sport-events'>
      {!isEmpty(title) && (
        <div className='sport-events__header'>
          <h3 className='sport-events__title'>{title}</h3>
          {!sportUrlName && linkHash === SPORTS_TABS.UPCOMING_SPORTS && (
            <Link to='/sportsbook#upcoming-sports' className='sport-events__link-all'>
              {t('see_all')}
            </Link>
          )}
          {sportUrlName && (
            <TournamentLink
              sportUrlName={sportUrlName}
              categoryUrlName={categoryUrlName}
              tournamentUrlName={tournamentUrlName}
              className='sport-events__link-all'
            >
              {t('see_all')}
            </TournamentLink>
          )}
        </div>
      )}
      {layout.mobileDevice === true && isSwipeableCarousel ? (
        <div className='sport-events__list'>
          <EventCarousel events={filteredEvents} />
        </div>
      ) : isBigBoxes ? (
        <div className='sport-events__list sport-events__list-big-boxes'>
          {map(filteredEvents.slice(0, boxesCount), (event) => (
            <DedicatedEventProvider event={event} key={event.id}>
              <TopEvent className='sport-events__list-item sport-events__list-item--big-box' />
            </DedicatedEventProvider>
          ))}
        </div>
      ) : (
        <Scrollbar className='sport-events__list' autoHeight scrollable={false}>
          {map(filteredEvents, (event) => (
            <DedicatedEventProvider event={event} key={event.id}>
              <TopEvent className='sport-events__list-item' />
            </DedicatedEventProvider>
          ))}
        </Scrollbar>
      )}
    </div>
  );
};
export default SportEvents;
