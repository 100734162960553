import { SportSelector } from '@apollo/core/src/state/sport/sport';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import SportEvents from '../../../../features/Home/Tabs/Sports/SportEvents';
import useTranslate from '../../I18n/Interpreter';

const TopSports = ({ title = null, isSwipeableCarousel, isBigBoxes, bigBoxesCount }) => {
  const t = useTranslate();
  const finalTitle = useMemo(() => (!isEmpty(title) ? title : t('top_sports')), [title]);

  const topSports = SportSelector.selectTopEvents({
    limit: 5,
    excludeOutright: true,
  });

  if (isEmpty(topSports) === true) {
    return null;
  }

  return (
    <div className='home-sports-list home-sports-list--top-sports'>
      <SportEvents
        title={finalTitle}
        events={topSports}
        isSwipeableCarousel={isSwipeableCarousel}
        isBigBoxes={isBigBoxes}
        bigBoxesCount={bigBoxesCount}
      />
    </div>
  );
};
export default TopSports;
