import React from 'react';
import { BetItemProvider, getBonusAssignedToBet, useBetslipState, useSettings } from '@apollo/core';
import BetListItem from './BetListItem';

export default function BetList({ readonly, betItems = [], betType }) {
  const { lang } = useSettings();
  const { outcomesErrors, confirming, bonuses, bonusAssignments } = useBetslipState();
  const firstActiveBetIndex = betItems.findIndex(({ active }) => active);

  return React.useMemo(
    () => betItems.map((betItem, index) => (
      <BetItemProvider key={`${betItem.requestId}-${lang}`} betItem={betItem}>
        <BetListItem
          focused={index === firstActiveBetIndex}
          invalid={outcomesErrors[betItem.outcomeId]}
          amount={betItem.amount}
          readonly={readonly}
          confirming={confirming}
          alreadyPlaced={betItem.alreadyPlaced}
          bonuses={bonuses[betItem.requestId]}
          assignedBonus={getBonusAssignedToBet({
            requestId: betItem.requestId,
            bonusAssignments,
            bonuses,
          })}
          betType={betType}
        />
      </BetItemProvider>
    )),
    [
      betItems,
      lang,
      firstActiveBetIndex,
      outcomesErrors,
      readonly,
      confirming,
      bonuses,
      bonusAssignments,
      betType,
    ],
  );
}
