import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { COMPONENT_TYPES } from '@apollo/routing';

import { Bus, SiteConfigManager, useApplicationState } from '@apollo/core';
import cx from 'classnames';

const SportsTreeItem = ({ id, sport, active, toggleOpenedSportUrlName }) => {
  const { layout } = useApplicationState();

  const path = useMemo(
    () => SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.SPORT_PREMATCH}`),
    [COMPONENT_TYPES.SPORT_PREMATCH],
  );
  const toUrl = React.useMemo(
    () => generatePath(`${path}/:sportName`, {
      sportName: decodeURIComponent(sport.urlName || '') || null,
    }),
    [path, sport.urlName],
  );

  const onClickHandler = React.useCallback(() => {
    if (!layout.desktopDevice) {
      Bus.send({
        event: Bus.events.layout.setSideBarLeft,
        data: false,
      });
    }
    // historyPush(toUrl);
    toggleOpenedSportUrlName(sport.urlName);
    return true;
  }, [layout.desktopDevice, sport.urlName]);

  const className = cx(
    'sidebar-tree__section-item sidebar-tree__section-item--sport',
    `sport-${id}`,
    {
      active,
    },
  );

  return (
    <Link to={toUrl} className={className} onClick={onClickHandler}>
      <i className={`icon sportIcon sportIcon-${sport?.id}`} title={sport?.name} />
      <span className='name' title={sport?.id}>
        {sport?.name}
        {/* <sup>{` (${sport?.totalCount})`}</sup> */}
      </span>
    </Link>
  );
};
export default SportsTreeItem;
